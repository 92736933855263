import {SecretActionsConstants} from "../actions/stream-secrets";

type initialState = {
  secrets: any[]
}

const initialState: initialState = {
  secrets: []
}

const secretActionsConstants = new SecretActionsConstants()

const streamSecrets = (state = initialState, action) => {
  switch (action.type) {
    case secretActionsConstants.CREATE_ACTION_CONSTANT:
    return {
      secrets: [...state.secrets, action.streamsObject]
    }
    case secretActionsConstants.DELETE_ACTION_CONSTANT:
      return state
    case secretActionsConstants.GET_ACTION_CONSTANT:
      return state
    case secretActionsConstants.LIST_ACTION_CONSTANT:
      return {
        secrets: action.streamsObjects
      }
    case secretActionsConstants.UPDATE_ACTION_CONSTANT:
      state.secrets.map(s => {
        if(s.id == action.streamsObject.id)
          return action.streamsObject
        return s
      })
      return state
    default:
      return state;
  }
}

export default streamSecrets
