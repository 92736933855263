import styled from "react-emotion";
import Info from 'material-ui-icons/Info';
import * as React from 'react';
import {connect} from 'react-redux';
import {PRIMARY_COLOR} from "../main.style";
import {ICompany, IWebsite} from '../actions/qmon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { IStreamsProject } from "../models/projects";
import { IStreamsCompany } from "../models/companies";

interface IProps {
  onInfoClick?: (event: any) => void,
  location: Location
  company_name: string
  customBreadCrumb: string
  qmon_company: ICompany
  qmon_website: IWebsite,
  selectedStreamsCompany: IStreamsCompany,
  selectedStreamsProject: IStreamsProject
}

class BreadCrumbs extends React.Component<IProps, {
  title: string,
  showHelpIcon: boolean
}> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: "Partners Overview",
      showHelpIcon: false
    };

    this.updateTitle = this.updateTitle.bind(this);
  }

  public componentDidMount() {
    this.updateTitle(this.props.location ? this.props.location.pathname : null);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (this.props.location && this.props.location.pathname !== nextProps.location.pathname) {
      this.updateTitle(nextProps.location.pathname);
    }
    if (nextProps.onInfoClick) {
      this.setState({showHelpIcon: true})
    } else {
      this.setState({showHelpIcon: false})
    }
  }

  public applicationContext(){
    const loc  = this.props.location

    // Check the path to get the correct context information
    if(loc && loc.pathname.indexOf('quality-assurance') > -1 && // Make this magic string into a constant so it doesn't break again
       this.props.qmon_company &&
       this.props.qmon_website){
      if(this.props.qmon_website.name && this.props.qmon_company.name){
        const company = this.props.qmon_company.name
        const website = this.props.qmon_website.name
        return `${company} / ${website} /`
      }
    }

    if(loc && (loc.pathname.indexOf('streams') > -1 || loc.pathname.indexOf('labels-and-segments') > -1) && // same as above
       this.props.selectedStreamsCompany &&
       this.props.selectedStreamsProject){
      if(this.props.selectedStreamsProject.name && this.props.selectedStreamsCompany.name){
        const company = this.props.selectedStreamsCompany.name
        const project = this.props.selectedStreamsProject.name
        return `${company} / ${project} /`
      }
    }
    // Extra check if not null, otherwise the enduser will see 'null' instead.
    return this.props.company_name ? this.props.company_name + ' /' : ''
  }

  public render() {
    const onInfoClick  = this.props.onInfoClick
    const customBreadCrumb  = this.props.customBreadCrumb
    const defaultBreadCrumb = (this.props.location ? this.props.location.pathname.replace(/[\/-]/g, " ") : '')
    // break down and capitalize first letter of each word
    const capitalized = defaultBreadCrumb.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
    
    const breadCrumb = `${this.applicationContext()} ${customBreadCrumb ? customBreadCrumb : capitalized}`
    return (
      <BreadCrumb>
        <Div>
          {
            (this.state.showHelpIcon)
              ? <span>&nbsp;<Info
                onClick={onInfoClick}
                style={{ "cursor": "pointer", "color": PRIMARY_COLOR, marginBottom: '-5px'}} />
                &nbsp;
              </span>
              : null
          }
          <FontAwesomeIcon style={{marginRight: '5px', marginTop: '20px'}} icon={faMapMarkerAlt} />
          {breadCrumb}
        </Div>
      </BreadCrumb>
    )
  }

  public updateTitle(path) {
    const array = path.split("/");

    const text = array.join(" ");
    let title = text.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    // Remove mongodb hex hashes from title, hacky I know.
    title = title.replace(/[0-9a-f]{24}/, "")

    // Remove Quality-assurance from title, hacky I know.
    title = title.replace(/Quality-assurance/, "")

    // Remove Labels-and-segments from title, hacky I know.
    title = title.replace(/Labels-and-segments/, "")

    this.setState({title});
  }
}

function mapStateToProps(state) {
  return {
    customBreadCrumb: state.appProperties.customBreadCrumb,
    location: state.router.location,
    company_name: state.settings.company_name,
    onInfoClick: state.appProperties.onInfoClick,
    qmon_company: state.qmon.selected.company,
    qmon_website: state.qmon.selected.website,
    selectedStreamsCompany: state.streamCompanies.selected,
    selectedStreamsProject: state.streamProjects.selected
  };
}

export default connect(mapStateToProps)(BreadCrumbs);

const BreadCrumb = styled("div")({
  display: 'inline-block',
  backgroundColor: 'white',
  width: '100%',
  marginTop: '-0.5px',
  // padding: '10px 40px',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.12)'
});

const Div = styled("div")({
  color: PRIMARY_COLOR,
  fontSize: '1.25rem',
  paddingBottom: '10px',
  marginTop: '-6px',
  marginLeft: '43px',
});
