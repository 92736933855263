import { Module, Role } from "../../typings/config";
import jwtDecode from 'jwt-decode'

interface IModule{
  token: string
  roles: Role[]
}

export type IModules = {
  [key: string]: IModule;
}

export interface IUser {
  id: string
  email: string
  first_name: string
  last_name: string
  features: object
  modules: IModules
  roles? : object
  user_id? : string
  groups: String[]
  companies: number[]
  password?: string
  password_confirm?: string
  legacy_dsm?: boolean
  company_context?: number
}

export interface ITokenResponse {
  access: string,
  refresh: string
}

export default class User implements IUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  features: Module[];
  modules: IModules;
  refresh_token?: string
  access_token?: string
  groups: string[]
  companies: []

  password?: string
  password_confirm?: string
  legacy_dsm?: boolean
  company_ctx?: number

  constructor(user){
    this.companies = user.companies || []
    if(user.groups){
      if(typeof user.groups[0] === 'object'){
        this.groups = user.groups.map(g => g.name) || []
      } else {
        this.groups = user.groups
      }
    } else {
      this.groups = []
    }
    this.refresh_token = user.refresh_token || null
    this.access_token  = user.access_token  || null
    this.id = user.user_id || user.id || ""
    this.email = user.email || ""
    this.first_name = user.first_name || ""
    this.last_name = user.last_name || ""
    this.features = user.features || []
    this.modules = user.modules || {}
    this.legacy_dsm = user.legacy_dsm || false
    this.company_ctx = undefined
  }

  public feature_enabled(feature, company=this.get_company_context()){
    if(company && this.features[company]){
      return this.features[company].includes(feature)
    } else {
      return false
    }
  }

  public get_company_context(){
    if(this.company_ctx) return this.company_ctx
    return sessionStorage.getItem('selected_stream_company_id')
  }

  public set_company_context(company){
    this.company_ctx = company.id
  }

  public authorized(){
    return !!this.access_token
  }

  public privacy_and_compliance_enabled(){
    return this.feature_enabled('privacy_and_compliance')
  }



  public segments_enabled(){
    return this.feature_enabled('segments')
  }

  public labels_enabled(){
    return this.feature_enabled('labels')
  }

  public legacy_dsm_enabled(){
    return this.feature_enabled('legacy_dsm')
  }

  public rolesFor(module: Module){
    return this.modules?.[module]?.roles ?? []
  }

  public qa_enabled(){
    return this.feature_enabled('qa') &&
           !!this.token_for('qa')
  }

  public qa2py_enabled() {
    return this.feature_enabled('qa2py')
  }

  public operator_enabled(){
    return this.feature_enabled('operator')
  }

  public save(){
    sessionStorage.setItem('user', JSON.stringify(this))
  }

  public token_for(module: Module){
    if(this.modules?.[module])
      return this.modules[module]['token']

    return null
  }

  public isValid(){
    return (this.email.length > 0);
  }

  public static logout(){
    sessionStorage.removeItem('user')
  }

  public static exists(user){
    return !!user.id
  }

  public static fromToken({access, refresh}){
    let current_user = User.get()
    let user = {}
    if(access){
      user = jwtDecode(access)
      user['refresh_token'] = refresh
      user['access_token']  = access
      if(current_user.id)
        user['groups'] = [...user['groups'], ...current_user['groups']]
    }
    return new User(user)
  }

  public add_group(group, company_id){
    this.groups.push(`${group}_company_${company_id}`)
    this.save()
  }

  public static get(){
    try {
      return new this(JSON.parse(sessionStorage.getItem('user') || ""))
    } catch (e){
      return new this({})
    }
  }
}
