import * as React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { PRIMARY_COLOR, ACCENT_COLOR } from "../main.style";


const primaryTheme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR
        },
        secondary: {
            main: ACCENT_COLOR
        }
    },
    overrides: {
        MuiButton: {
          raisedPrimary: {
            color: 'white',
          },
        },
      }
})

/**
 * This button is a wrapper component. The default button color is set to `primary`
 * 
 * @param props Button props as defined on https://material-ui.com/components/buttons/
 */
export const Btn = (props: ButtonProps) =>
    <MuiThemeProvider theme={primaryTheme}>
        <Button variant="contained" color="primary" {...props}>
            {props.children}
        </Button>
    </MuiThemeProvider>
