import React, {Component} from 'react';
import {CSSTransitionGroup} from 'react-transition-group';
import {NavLink} from 'react-router-dom';

import styled from 'react-emotion';
import {LIGHT_TEXT_COLOR, SIDEBAR_COLOR} from '../main.style';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown'
import {faCaretUp} from '@fortawesome/free-solid-svg-icons/faCaretUp'

const SidebarContainer = styled("div")({
  display: 'inline-block',
  height: '100vh',
  width: '100%',
  background: SIDEBAR_COLOR,
  color: LIGHT_TEXT_COLOR,
  overflowY: 'auto'
});
const MenuList = styled("ul")({
  listStyle: 'none',
  padding: '0'
});

export class Sidebar extends Component {
  render() {
    return (
      <SidebarContainer>
        <MenuList className="menu">
          {this.props.children}
        </MenuList>
      </SidebarContainer>
    )
  }
}

export const SidebarLogo = styled("img")(({sidebarBig = true}) => (sidebarBig === true ? {
  width: '55%',
  padding: '15px 15% 10px'
} : {
  width: '40%',
  transition: '0.2s',
  padding: '10px 30%'
}));
const MenuItemListItem = styled("li")({
  cursor: 'pointer',
}, ({sidebarBig = true}) => ({
  marginTop: sidebarBig === true ? '25px' : '0px',
}));
const MenuItemNavLink = styled(NavLink)({
  color: 'rgba(255, 255, 255, 0.35)',
  display: 'block',
  padding: '8px 0 8px 15px',
  fontSize: '12px',
  textDecoration: 'none',
  marginTop: '0px',
  opacity: '0.83',
});

const Tag = styled('span')({
  backgroundColor: '#4CB3CF',
  color: '#FFF',
  textDecoration: 'upperCase',
  width: '120px',
  borderRadius: '600px',
  marginRight: '20px',
  marginLeft: '20px',
  paddingLeft: '5px',
  paddingRight: '5px',
  fontSize:'12px',
  fontWeight: 900,
})

export class MenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSubmenu: true
    };

    this.toggleSubmenu = this.toggleSubmenu.bind(this);
  }

  render() {
    return (
      <MenuItemListItem sidebarBig={this.props.showName}>
        <div style={this.props.showName ? {
          width: '100%',
          height: '4px',
          backgroundColor: 'rgba(0,0,0,0.06)',
          borderTop: '1px solid rgba(0,0,0,0.2)',
          borderBottom: '1px solid rgba(255,255,255, 0.08)'
        } : {display: 'none'}}/>
        <MenuItemNavLink
          style={!this.props.showName ? {display: 'none'} : null}
          to={this.props.link}
          exact={this.props.exact}
          onClick={this.props.children ? this.toggleSubmenu : null}
        >
          <span>{this.props.name}</span>
          {this.props.children ?
            <FontAwesomeIcon
              style={{float: 'right', marginRight: '20px'}}
              icon={this.state.showSubmenu ? faCaretDown : faCaretUp} />
            : null}
        </MenuItemNavLink>
        <CSSTransitionGroup
          transitionName="submenu-items"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {this.state.showSubmenu ? this.props.children : null}
        </CSSTransitionGroup>
      </MenuItemListItem>
    )
  }

  toggleSubmenu(e) {
    e.preventDefault();

    this.setState({
      showSubmenu: !this.state.showSubmenu
    });
  }
}

const MenuItemList = styled("ul")({
  cursor: 'pointer',
  paddingLeft: '0',
  marginLeft: '8px',
})
const SubmenuItemNavLink = styled(NavLink)({
  marginLeft: '-27px',
  color: 'rgba(255, 255, 255, 0.8)',
  display: 'block',
  padding: '0 0 0 15px',
  fontSize: '14px',
  textDecoration: 'none',
  opacity: '0.83',
  paddingTop: '10',
  paddingBottom: '10',
  ".active": {
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    opacity: '1.0',
    color: 'white',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
  },
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
})

const SubmenuItemNavLinkExternal = styled("a")({
  marginLeft: '-27px',
  color: 'rgba(255, 255, 255, 0.8)',
  display: 'block',
  padding: '0 0 0 15px',
  fontSize: '14px',
  textDecoration: 'none',
  opacity: '0.83',
  paddingTop: '10',
  paddingBottom: '10',
  ".active": {
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    opacity: '1.0',
    color: 'white',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
  },
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
})

export class SubmenuItem extends Component {
  render() {
    return (
      <MenuItemList>
        <SubmenuItemNavLink
          style={(window.location.pathname.indexOf(this.props.link) !== -1)
            ? {
              boxSizing: 'border-box',
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
              opacity: '1.0',
              color: 'white',
              borderTop: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
            }
            : {}}
          to={this.props.link} exact={this.props.exact}>
          <FontAwesomeIcon icon={this.props.icon} style={{
            display: 'inline-block',
            width: '20px',
            textAlign: 'center',
            marginLeft: '16px',
            marginRight: '16px',
          }} />
          {this.props.showName ?
            <span>{this.props.name} {this.props.isBeta ? <Tag>&nbsp;BETA&nbsp;</Tag> : null}</span>
            : null}
        </SubmenuItemNavLink>
      </MenuItemList>
    )
  }
}

export class SubmenuItemExternal extends Component {
  render() {
    return (
      <MenuItemList>
        <SubmenuItemNavLinkExternal
          style={(window.location.pathname.indexOf(this.props.link) !== -1)
            ? {
              boxSizing: 'border-box',
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
              opacity: '1.0',
              color: 'white',
              borderTop: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
            }
            : {}}
         href={this.props.link} target={this.props.target}>
          <FontAwesomeIcon icon={this.props.icon} style={{
            display: 'inline-block',
            width: '20px',
            textAlign: 'center',
            marginLeft: '16px',
            marginRight: '16px',
          }} />
          {this.props.showName ?
            <span>{this.props.name} {this.props.isBeta ? <Tag>&nbsp;BETA&nbsp;</Tag> : null}</span>
            : null}
        </SubmenuItemNavLinkExternal>
      </MenuItemList>
    )
  }
}
