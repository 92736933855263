import {RIGHTS_LIST} from '../actions';

export interface IRole {
  id: number,
  name: string,
}

export interface IRight {
  id: number,
  value: string,
  roles: IRole[]
}

export interface IRightState {
  rights: IRight[]
}

const initialState: IRightState = {
  rights: [
    {
      id: 1,
      value: "LIST_ASSIGNED_COMPANIES",
      roles: [
        {
          id: 1,
          name: "Data Protection Officer"
        },
        {
          id: 2,
          name: "Controller Engineer"
        },
        {
          id: 3,
          name: "External Engineer"
        },
        {
          id: 4,
          name: "Admin"
        }
      ]
    }
  ]
};

const rights = (state = initialState, action) => {
  switch (action.type) {
    case RIGHTS_LIST:
      return {
        ...state,
        rights: action.rights,
      };
    default:
      return state;
  }
};

export default rights;