import axios, { AxiosRequestConfig } from 'axios';
import { store } from '..';
import { reload } from '../actions/users';
import User from '../models/users/user'
import { csrfcookie } from './helpers'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export let streamsWebsocketURL = "ws://localhost:8000"
export let baseUrl = 'http://localhost:8000'

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" &&
  process.env.REACT_APP_ENVIRONMENT === "production") {
  streamsWebsocketURL = 'wss://prod.api.datastreams.io'
  baseUrl = 'https://prod.api.datastreams.io'
}

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" &&
  process.env.REACT_APP_ENVIRONMENT === "acceptance") {
  streamsWebsocketURL = 'wss://megazord-staging.api.datastreams.io'
  baseUrl = 'https://megazord-staging.api.datastreams.io'
}

// Should be used to call the API
export const apiUrl = `${baseUrl}/api`


const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: apiUrl,
  headers: {
    accept: "application/json"
  },
  transformRequest: [function (data, headers) {
    if (User.get().authorized())
      headers['Authorization'] = "Bearer " + User.get().access_token

    headers['Content-Type'] = 'application/json;charset=utf-8'
    headers['X-CSRFToken']  =  csrfcookie()
    if(typeof data === 'object'){
      return JSON.stringify(data)
    } else {
      return data
    }
  }],
  validateStatus: status => {
    const isValid = status >= 200 && status < 400
    const isAuthorized = status !== 401
    return isValid && isAuthorized
  }
}

const streamsInstance = (() => {
  const ax = axios.create(axiosRequestConfig);
  ax.defaults.xsrfCookieName = 'csrftoken'
  ax.defaults.xsrfHeaderName = "X-CSRFTOKEN"

  const retryInstance = axios.create(axiosRequestConfig)
  retryInstance.defaults.xsrfCookieName = 'csrftoken'
  retryInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN"

  createAuthRefreshInterceptor(ax, (failedRequest) => {
    return new Promise((resolve, reject) => {
      reload()((action) => {
        store.dispatch(action)
        resolve()
      })
    })
  }, { retryInstance });
  return ax
})();

export default streamsInstance;
