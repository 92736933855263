import {connectRouter} from 'connected-react-router'
import {History} from 'history';
import {combineReducers} from 'redux';

import appProperties from './appProperties'
import users from './users';
import companies from './companies';
import connection from './connection';
import domains from './domains';
import labels from './labels'
import loading from './loading';
import message from './message';
import policy from './policy';
import qmon from './qmon';
import rights from './rights';
import settings from './settings';
import streamCompanies from './stream-companies'
import streamConnections from './stream-connections'
import streamSegments from './stream-segments'
import streamDomains from './stream-domains'
import streamProjects from './stream-projects'
import streamUsers from './stream-users'
import streamSecrets from './stream-secrets'
import streams from './streams';
import alerts from './alerting'

export default (history: History) => combineReducers({
  settings,
  users,
  companies,
  domains,
  labels,
  message,
  loading,
  rights,
  policy,
  connection,
  streams,
  streamCompanies,
  streamConnections,
  streamSegments,
  streamDomains,
  streamProjects,
  streamUsers,
  streamSecrets,
  appProperties,
  alerts,
  router: connectRouter(history),
  qmon
});
