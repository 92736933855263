// Fonts
export const FONT_FAMILY = "'Open Sans', sans-serif";

// Colors
export const PRIMARY_COLOR = '#31B9E3';
export const ACCENT_COLOR = '#DB3B21';
export const DARK_TEXT_COLOR = '#383838';
export const SIDEBAR_COLOR = '#2E384D';
export const LIGHT_TEXT_COLOR = '#FFFFFF';
export const BODY_COLOR = '#EFEFEF';

// datastreams color scheme 01-2019
/* Heading 01 */
export const DEEP_BLACK = '#0a0b09';
// TODO The gradient is from SEA_GREEN to COOL_BLUE, define it here

/* Heading 02 */
export const MIDNIGHT = '#1c404c';
export const COOL_BLUE = '#1dbadf';
export const SEA_GREEN = '#42b38e';

/* Heading 03 */
export const AIR_FORCE = '#39627a';
export const SKY_BLUE = '#72caeb';
export const MINT_GREEN = '#52bbb5';

/* Details */
export const CLOUDY = '#6fa4c4';
export const BABY_BLUE = '#a2d9f0';
export const SPA_GREEN = '#88cdd3';

/* tables */
export const ICON_COLOR = SEA_GREEN
export const TABLE_LINE_COLOR = '#dddcdc'
export const VALUE_GRAY = '#dddcdc'
export const VALUE_RED = '#DB3B21'
export const VALUE_YELLOW = '#fca609'
export const VALUE_GREEN = '#42b38e'
export const TABLE_TOP_HEADER_HEIGHT = '32px';
export const TABLE_BOTTOM_HEADER_HEIGHT = '48px';
export const TABLE_CELL_HEIGHT = '32px';
export const TABLE_TOP_HEADER_LINE_WIDTH = '2px';
export const TABLE_ROW_LINE_WIDTH = '1px';

// Sizes
export const HEADER_HEIGHT = '40px';
export const SIDEBAR_WIDTH = '240px';
export const SIDEBAR_WIDTH_SMALL = '60px';

// Palettes
export const PRIMARY_PALETTE = { // BASED ON blueGrey ('material-ui/colors/blueGrey');
  '50': '#eceff1',
  '100': '#cfd8dc',
  '200': '#b0bec5',
  '300': '#90a4ae',
  '400': '#78909c',
  '500': PRIMARY_COLOR,//orginal '#607d8b'
  '600': '#546e7a',
  '700': '#455a64',
  '800': '#37474f',
  '900': '#263238',
  'A100': '#cfd8dc',
  'A200': '#b0bec5',
  'A400': '#78909c',
  'A700': '#455a64',
  // 'contrastDefaultColor': "light"
};

export const ACCENT_PALETTE = { // BASED ON red ('material-ui/colors/red');
  '50': '#ffebee',
  '100': '#ffcdd2',
  '200': '#ef9a9a',
  '300': '#e57373',
  '400': '#ef5350',
  '500': '#f44336',// orginal #f44336
  '600': '#e53935',
  '700': '#d32f2f',
  '800': '#c62828',
  '900': '#b71c1c',
  'A100': '#ff8a80',
  'A200': ACCENT_COLOR,//orginal '#ff5252'
  'A400': '#ff1744',
  'A700': '#d50000',
  // contrastDefaultColor: 'light'
};

// // Colors
// $secondary-color: rgba(53, 64, 82, 0.8);
// $dark-primary-color:    rgba(0, 0, 0, 0.2);
// $light-primary-color:   rgba(255, 255, 255, 0.1);
// $body-color:            #EFEFEF;
// $secondary-text-color:  #757575;
// $divider-color:         #BDBDBD;
// $success-color:         #67EB51;
// $warning-color:         #F6A623;
// $danger-color:          #E63020;

