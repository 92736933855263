import { LIST_STREAM_DOMAINS, CREATE_STREAM_DOMAINS, DELETE_STREAM_DOMAINS } from '../actions/index';
import { IStreamDomain } from '../actions/stream-domains';

type initialState = {
    domains: IStreamDomain[]
}
export const initialState: initialState = {
    domains: []
}

const streamDomains = (state = initialState, action) => {
    switch(action.type) {
        case LIST_STREAM_DOMAINS:
            return {
                ...state,
                domains: [...action.domains]
            }
        case CREATE_STREAM_DOMAINS:
            return {
                ...state,
                domains: [...state.domains, {...action.domain}]
            }
        case DELETE_STREAM_DOMAINS:
        default:
            return state
    }
}

export default streamDomains
