import {CREATE_STREAM_PROJECT,
  DELETE_STREAM_PROJECT,
  GET_STREAM_PROJECT,
  LIST_STREAM_PROJECTS,
  UPDATE_STREAM_PROJECT,
  SELECT_STREAM_PROJECT} from '../actions'
import {IStreamsProject} from '../models/projects'
import { storeEntityIdByArray, storeEntityId } from '../helpers/storage';

type initialState = {
  projects: IStreamsProject[]
  selected?: IStreamsProject
}

const initialState: initialState = {
  projects: [] as IStreamsProject[]
}

const streamProjects = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STREAM_PROJECT:
      const projects = [...state.projects, action.project]
      // Automatically set the freshly created project as the selected project.
      // A lot of code depends on having a project selected.
      if(!state.selected) {
        return {
          selected: {...action.project},
          projects,
        }
      }

      return {
        ...state,
        projects,
      }
    case DELETE_STREAM_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(project => project.id !== action.project.id)
      }
    case GET_STREAM_PROJECT:
      return state
    case LIST_STREAM_PROJECTS:
      let selectedProject: IStreamsProject | null = null

      // Check if we have a previously selected project.
      if(sessionStorage.getItem("selected_stream_project_id") && action.projects.find(p => p.id == sessionStorage.getItem("selected_stream_project_id"))) {
        const selectedId = sessionStorage.getItem("selected_stream_project_id") || '-2222'
        selectedProject = storeEntityIdByArray("selected_stream_project_id", action.projects, parseInt(selectedId)) || null
      }else if (!selectedProject) {
        // No prior selection found, select the first project.
        selectedProject = action.projects[0]
      }

      sessionStorage.setItem("selected_stream_company_id", ""+selectedProject?.company)

      return {
        ...state,
        projects: [...action.projects],
        selected: {...storeEntityId("selected_stream_project_id", selectedProject!)}
      }
    case UPDATE_STREAM_PROJECT:
      return state
      case SELECT_STREAM_PROJECT:
          if (action.project) {
            sessionStorage.setItem("selected_stream_project_id", action.project.id)
          }
          return {
            ...state,
            selected: {...action.project}
          }
    default:
      return state;
  }
}

export default streamProjects
