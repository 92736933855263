import Immutable from 'seamless-immutable'
import {store} from '..';
import qmonInstance from '../helpers/qmon-instance';
import {showMessage} from './message';
import {showLoading, hideLoading} from './loading';
import User from '../models/users/user'

export const QmonActions = {
  QMON_ALERTS_LIST: 'QMON_ALERTS_LIST',
  QMON_ADOBE_VARIABLES_GET: 'QMON_ADOBE_VARIABLES_GET',
  QMON_COMPANIES_LIST: 'QMON_COMPANIES_LIST',
  QMON_COMPANY_CREATE: 'QMON_COMPANY_CREATE',
  QMON_COMPANY_UPDATE: 'QMON_COMPANY_UPDATE',
  QMON_COMPANY_REMOVE: 'QMON_COMPANY_REMOVE',
  QMON_DOMAINS_LIST: 'QMON_DOMAINS_LIST',
  QMON_DOMAIN_CREATE: 'QMON_DOMAIN_CREATE',
  QMON_DOMAIN_UPDATE: 'QMON_DOMAIN_UPDATE',
  QMON_DOMAIN_REMOVE: 'QMON_DOMAIN_REMOVE',
  QMON_WEBSITES_LIST: 'QMON_WEBSITES_LIST',
  QMON_WEBSITE_ACTIVE: 'QMON_WEBSITE_ACTIVE',
  QMON_WEBSITE_CREATE: 'QMON_WEBSITE_CREATE',
  QMON_WEBSITE_UPDATE: 'QMON_WEBSITE_UPDATE',
  QMON_WEBSITE_REMOVE: 'QMON_WEBSITE_REMOVE',
  QMON_SELECT_COMPANY: 'QMON_SELECT_COMPANY',
  QMON_SELECT_WEBSITE: 'QMON_SELECT_WEBSITE',
  QMON_SELECT_WEBSITE_BY_ID: 'QMON_SELECT_WEBSITE_BY_ID',
  QMON_EVENT_ALERT_SETTINGS_GET: 'QMON_EVENT_ALERT_SETTINGS_GET',
  QMON_EVENT_ALERT_SETTINGS_UPDATE: 'QMON_EVENT_ALERT_SETTINGS_UPDATE',
  QMON_EVENTS_LIST: 'QMON_EVENTS_LIST',
  QMON_EVENT_CREATE: 'QMON_EVENT_CREATE',
  QMON_EVENT_DELETE: 'QMON_EVENT_DELETE',
  QMON_ELEMENT_ALERT_SETTINGS_GET: 'QMON_ELEMENT_ALERT_SETTINGS_GET',
  QMON_ELEMENT_ALERT_SETTINGS_UPDATE: 'QMON_ELEMENT_ALERT_SETTINGS_UPDATE',
  QMON_ELEMENTS_LIST: 'QMON_ELEMENTS_LIST',
  QMON_ELEMENT_CREATE: 'QMON_ELEMENT_CREATE',
  QMON_ELEMENT_DELETE: 'QMON_ELEMENT_DELETE',
  QMON_ELEMENT_UPDATE: 'QMON_ELEMENT_UPDATE',
  QMON_CONTENT_LIST: 'QMON_CONTENT_LIST',
  QMON_CONTENT_CREATE: 'QMON_CONTENT_CREATE',
  QMON_CONTENT_DELETE: 'QMON_CONTENT_DELETE',
  QMON_CONTENT_UPDATE: 'QMON_CONTENT_UPDATE',
  QMON_EVENT_SUBSCRIBE: 'QMON_EVENT_SUBSCRIBE',
  QMON_EVENT_UNSUBSCRIBE: 'QMON_EVENT_UNSUBSCRIBE',
  QMON_ELEMENT_SUBSCRIBE: 'QMON_ELEMENT_SUBSCRIBE',
  QMON_ELEMENT_UNSUBSCRIBE: 'QMON_ELEMENT_UNSUBSCRIBE',
  QMON_INVITATION_DELETE: 'QMON_INVITATION_DELETE',
  QMON_INVITATIONS_LIST: 'QMON_INVITATIONS_LIST',
  QMON_INVITATION_RESEND: 'QMON_INVITATION_RESEND',
  QMON_INVITATION_SEND: 'QMON_INVITATION_SEND',
  QMON_SUMMARIES_GET: 'QMON_SUMMARIES_GET',
  QMON_USERS_LIST: 'QMON_USERS_LIST',
  QMON_USER_DELETE: 'QMON_USER_DELETE',
  QMON_USER_SETROLE: 'QMON_USER_SETROLE',
  QMON_USER_UPDATE: 'QMON_USER_UPDATE',
  QMON_USER_PASSWORD_UPDATE: 'QMON_USER_PASSWORD_UPDATE',
  QMON_USER_PROFILE_GET: 'QMON_USER_PROFILE_GET',
  QMON_USER_PROFILE_UPDATE: 'QMON_USER_PROFILE_UPDATE',
  QMON_VALIDATION_SUBSCRIBE: 'QMON_VALIDATION_SUBSCRIBE',
  QMON_VALIDATION_UNSUBSCRIBE: 'QMON_VALIDATION_UNSUBSCRIBE',
  QMON_VALIDATION_ALERT_SETTINGS_GET: 'QMON_VALIDATION_ALERT_SETTINGS_GET',
  QMON_VALIDATION_ALERT_SETTINGS_UPDATE: 'QMON_VALIDATION_ALERT_SETTINGS_UPDATE',
  QMON_VALIDATION_OPERATORS_GET: 'QMON_VALIDATION_OPERATORS_GET',
  QMON_DIAGNOSTICS: 'QMON_DIAGNOSTICS'
}

export interface ISubscription {
  websiteId: string,
  eventId: string,
  elementId: null,
  contentValidationId: null,
  nonUsersEmails: string,
  subscribed: boolean,
  subscriptionId: string,
  subscriptionType: string
}

export const EMPTY_SUBSCRIPTION = {
  contentValidationId: null,
  eventId: "",
  elementId: null,
  nonUsersEmails: "",
  subscribed: false,
  subscriptionType: "",
  websiteId: "",
}

export interface IAlert {
  active: boolean,
  alertType: AlertType,
  alertTypeName: string,
  duration: number,
  eventId: string,
  id: string,
  itemId: string,
  lastTriggered: number,
  lastValue: string,
  startDate: string,
  startTime: string,
  subscribed: boolean,
  subscriptionId: string,
  validationName: string,
}

export enum AlertType {
  OUTAGE = 'outage',
  UNKOWN = 'unkown',
}

/**
 * This enum allows options for filtering alerts by entity type.
 * Filtering triggers a new request to the backend.
 */
export enum AlertEntityType {
  ALL = 'all',
  EVENT = 'event',
  ELEMENT = 'element',
  CONTENT = 'content',
}

export interface IEvent {
  id: string,
  websiteId: string,
  name: string,
  type: string,
  parentId: string,
  dcfEvent: string,
  eventQueue: string,
  matching: [
    [{
      'property': string,
      'variable': string,
      'equal': boolean,
      'operator': string,
      'value': string
    }]
  ],
  subscription: ISubscription,
  used: false
}

export const EMPTY_RULE = {
  equal: true,
  operator: "equals",
  property: "pathname",
  value: null,
  variable: null
}

export const EMPTY_ADOBE_RULE = {
  equal: true,
  operator: "equals",
  value: "",
  range: [null, null],
  caseSensitive: false,
  variable: "v",
  parameter: "v",
  numValue: null,
}

export const EMPTY_VALIDATOR_RULE = {
  caseSensitive: true,
  equal: true,
  numValue: null,
  operator: "equals",
  property: "js",
  range: [null, null],
  value: "",
  variable: ""
}

export const EMPTY_EVENT = Immutable({
  id: null,
  websiteId: null,
  name: null,
  type: 'pageload',
  parentId: null,
  dcfEvent: null,
  eventQueue: null,
  matching: [
    [{...EMPTY_RULE}]
  ],
  subscription: {...EMPTY_SUBSCRIPTION}
})

export interface IElement {
  id: string,
  websiteId: string,
  elementParentId: string,
  parentId: string,
  elementName: string,
  elementType: string,
  type: string,
  name: string,
  groupId: number,
  level: number,
  event: string,
  eventId: string,
  eventParentId: string,
  elementLoad: any,
  // elementCompliance: any,
  // consent configuration
  consentCategoryId: string,
  consentCategoryName: string,
  // js
  jsObjectName: string,
  jsObjectCheckType: string,
  jsObjectKey: string,
  // tag
  tagEventType: string,
  tagType: string,
  tagEventName: string,
  // dcf
  dcfEvent: string,
  eventQueue: string,
  // http
  requestUrl: string,
  requestOperator: string,
  root: any,
  lastTimestamp: any,
  subevent: any,
  subscription: ISubscription
}

export const EMPTY_ELEMENT = {
  id: null,
  consentCategoryId: null,
  consentCategoryName: null,
  dcfEvent: null,
  elementLoad: {
    lastDay: null,
    lastDayThreshold: 90,
    lastHour: null,
    lastHourThreshold: null,
    lastWeek: null
  },
  elementName: null,
  elementParentId: null,
  elementType: "dcf",
  event: null,
  eventId: "",
  eventParentId: null,
  eventQueue: null,
  groupId: null,
  jsObjectCheckType: "",
  jsObjectKey: null,
  jsObjectName: "",
  level: null,
  name: null,
  parentId: null,
  requestOperator: null,
  requestUrl: null,
  subscription: {...EMPTY_SUBSCRIPTION},
  tagEventName: null,
  tagEventType: null,
  tagType: null,
  type: null,
  websiteId: ""
}

export interface IContentValidation {
  id: string,
  caseSensitive: boolean,
  displayName: string,
  elementId: string,
  elementName: string,
  elementType: string,
  equal: boolean,
  eventId: string,
  eventName: string,
  eventType: string,
  jsObjectCheckType: string,
  jsObjectName: string,
  matching: string,
  numValue: string,
  operator: string,
  parameter: string,
  ranges: [[string, string]],
  subscription: ISubscription,
  tagEventType: string,
  tagType: string,
  values: [string],
  validations: [IContentValidation],
  variable: string,
  websiteId: string
}

export const EMPTY_CONTENT_VALIDATION = Immutable({
  id: null,
  caseSensitive: true,
  displayName: "",
  elementId: "",
  elementName: null,
  elementType: "js",
  equal: true,
  eventId: "",
  eventName: null,
  eventType: null,
  jsObjectCheckType: null,
  jsObjectName: null,
  matching: [],
  numValue: null,
  operator: "",
  parameter: "",
  ranges: [[null, null]],
  tagEventType: null,
  tagType: null,
  validations: [],
  values: [""],
  variable: "",
  websiteId: ""
})

export interface ICompany {
  id: string,
  name: string,
  createdOn: string,
  modifiedOn: string
}

export const EMPTY_COMPANY = {
  id: null,
  name: "",
  createdOn: "",
  modifiedOn: null
}

export interface IDomain {
  assigned: boolean,
  companyId: string,
  createdOn: number,
  id: string,
  url: string
}

export const EMPTY_DOMAIN = {
  assigned: false,
  companyId: null,
  createdOn: null,
  id: null,
  url: null
}

export interface IPasswordChange {
  passwordConfirm: string,
  passwordNew: string,
  passwordOld: string,
  username: string
}

export const EMPTY_PASSWORD_CHANGE = {
  passwordConfirm: "",
  passwordNew: "",
  passwordOld: "",
  username: ""
}

export interface IWebsiteSummaries {
  websiteName: string,
  websiteId: string,
  summary:{
    volume: number,
    elementLoad: number,
    elementCompliance: number,
    contentValidation: number,
    alerts:{
      active: number,
      nieuw: number,
      closed: number
    }
  }
}

export interface ISummaries {
    id: string,
    websites:IWebsiteSummaries[],
    total:{
      volume: number,
      elementLoad: number,
      elementCompliance: number,
      contentValidation: number,
      alerts:{
        active: number,
        nieuw: null,
        closed: null
      }
    },
    eventGraph:{
      labels: string[],
      dataList: number[]
    }
}

export const EMPTY_SUMMARIES: ISummaries = {
  id: '',
  websites:[],
  total:{
    volume: 0,
    elementLoad: 0,
    elementCompliance: 0,
    contentValidation: 0,
    alerts:{
      active: 0,
      nieuw: null,
      closed: null
    }
  },
  eventGraph:{
    labels: [],
    dataList: []
  }
}

export interface IUser {
  active: boolean,
  banMessage: string,
  banned: boolean,
  companyId: string,
  createdOn: number,
  deleted: boolean,
  email: string,
  firstName: string,
  id: string,
  lastIp: string,
  lastLogin: number,
  lastName: string,
  roleId: string,
  superadmin: boolean,
  timezone: string
}

export const EMPTY_USER = {
  companyId: "",
  companyName: null,
  createdOn: null,
  email: "",
  id: null,
  invitationHash: null,
  modifiedOn: null,
  roleId: "user",
  roleName: null,
  status: null,
  usedOn: null
}

export interface IWebsite {
  id: string,
  collectionActive: boolean,
  companyId: string,
  companyName: string,
  createdOn: number,
  modifiedOn: number,
  name: string,
  urls: [string]
}

export const EMPTY_WEBSITE = {
  id: null,
  collectionActive: false,
  companyId: "",
  companyName: null,
  createdOn: null,
  modifiedOn: null,
  name: "",
  urls: []
}

export const ELEMENT_DEFAULT_SETTINGS = {
  id:"",
  load: {
    active: true,
    threshold: 90,
    period: "min60"
  },
  application: "onlyThis"
}

export interface IValidationOperator {
  id: string,
  label: string,
  numeric: boolean,
  textual: boolean,
  matchValue: boolean,
  rangedValue: boolean
}

export interface IAdobeVariable {
  id: string,
  name: string,
  numVal: boolean
}

const returnAlerts = alerts => {
  return ({
    type: QmonActions.QMON_ALERTS_LIST,
    alerts
  })
};

const returnAdobeVariables = (variables: IAdobeVariable[]) => {
  return ({
    type: QmonActions.QMON_ADOBE_VARIABLES_GET,
    variables
  })
};

const returnValidationOperators = (operators: IValidationOperator[]) => {
  return ({
    type: QmonActions.QMON_VALIDATION_OPERATORS_GET,
    operators
  })
};

const returnCompanies = (companies) => {
  let comps = null
  if(companies.companies){ comps = companies.companies }
  if(companies['admin/companies']){ comps = companies['admin/companies'] }

  return ({
    type: QmonActions.QMON_COMPANIES_LIST,
    companies: comps
  })
};

const createCompany = (company: ICompany) => {
  return ({
    type: QmonActions.QMON_WEBSITE_CREATE,
    company
  })
};

const deleteCompany = (companyId) => {
  return ({
    type: QmonActions.QMON_WEBSITE_REMOVE,
    companyId
  })
};

const updateCompany = (company: ICompany) => {
  return ({
    type: QmonActions.QMON_WEBSITE_UPDATE,
    company
  })
};

const returnDomains = (domains) => {
  return ({
    type: QmonActions.QMON_DOMAINS_LIST,
    domains
  })
};

const createDomain = (domain: IDomain) => {
  return ({
    type: QmonActions.QMON_DOMAIN_CREATE,
    domain
  })
};

const deleteDomain = (domainId) => {
  return ({
    type: QmonActions.QMON_DOMAIN_REMOVE,
    domainId
  })
};

const updateDomain = (domain: IDomain) => {
  return ({
    type: QmonActions.QMON_DOMAIN_UPDATE,
    domain
  })
};

const returnUsers = (users) => {
  return ({
    type: QmonActions.QMON_USERS_LIST,
    users: users["admin/users"]
  })
};

const returnUserProfile = (users) => {
  return ({
    type: QmonActions.QMON_USER_PROFILE_GET,
    users: users.users[0]
  })
};

const updatePassword = (passwordChange) => {
  return ({
    type: QmonActions.QMON_USER_PASSWORD_UPDATE,
    passwordChange
  })
};

const updateUserProfile = (users) => {
  return ({
    type: QmonActions.QMON_USER_PROFILE_UPDATE,
    users: users.users[0]
  })
};

const createUser = (user: IUser) => {
  return ({
    type: QmonActions.QMON_INVITATION_SEND,
    user
  })
};

const deleteUser = (userId) => {
  return ({
    type: QmonActions.QMON_USER_DELETE,
    userId
  })
};

const setRoleUser = (user) => {
  return ({
    type: QmonActions.QMON_USER_SETROLE,
    user
  })
};

const updateUser = (user: IUser) => {
  return ({
    type: QmonActions.QMON_USER_UPDATE,
    user
  })
};

const returnInvitations = (invitations) => {
  return ({
    type: QmonActions.QMON_INVITATIONS_LIST,
    invitations: invitations["admin/invitations"]
  })
};

const deleteInvitation = (invitationId) => {
  return ({
    type: QmonActions.QMON_INVITATION_DELETE,
    invitationId
  })
};

const updateInvitation = (invitation: IUser) => {
  return ({
    type: QmonActions.QMON_INVITATION_RESEND,
    invitation
  })
};

const returnWebsites = (websites) => {
  return ({
    type: QmonActions.QMON_WEBSITES_LIST,
    websites: websites.websites
  })
};

const activeWebsiteAction = (website: IWebsite) => {
  return ({
    type: QmonActions.QMON_WEBSITE_ACTIVE,
    website
  })
};

const createWebsiteAction = (website: IWebsite) => {
  return ({
    type: QmonActions.QMON_WEBSITE_CREATE,
    website
  })
};

const deleteWebsite = (websiteId) => {
  return ({
    type: QmonActions.QMON_WEBSITE_REMOVE,
    websiteId
  })
};

const updateWebsite = (website: IWebsite) => {
  return ({
    type: QmonActions.QMON_WEBSITE_UPDATE,
    website
  })
};

const returnEvents = (events) => {
  // @TODO : This is something that should be fixed in the backend.
  const dirtyArray = events.events;
  const dirtyFix = dirtyArray.map((e) => {
    e.volume.daily = Math.round(e.volume.daily);
    return e;
  });

  return ({
    type: QmonActions.QMON_EVENTS_LIST,
    events: dirtyFix
  })
};

const returnEventAlertSettings = (alertSettings) => {
  return ({
    type: QmonActions.QMON_EVENT_ALERT_SETTINGS_GET,
    alertSettings: alertSettings['events/settings'][0]
  })
};

const updateEventAlertSettings = (alertSettings) => {
  return ({
    type: QmonActions.QMON_EVENT_ALERT_SETTINGS_UPDATE,
    alertSettings
  })
};

const createEvent = (event: IEvent) => {
  return ({
    type: QmonActions.QMON_EVENT_CREATE,
    event
  })
};

const deleteEvent = (eventId) => {
  return ({
    type: QmonActions.QMON_EVENT_DELETE,
    eventId
  })
};

const returnElements = (elements) => {
  return ({
    type: QmonActions.QMON_ELEMENTS_LIST,
    elements: elements.elements
  })
};

const returnElementAlertSettings = (alertSettings) => {
  let transformedAlertSettings = ELEMENT_DEFAULT_SETTINGS

  if (alertSettings['elements/settings'].length > 0) {
    alertSettings = {...alertSettings['elements/settings'][0]}

    transformedAlertSettings = {
      application: alertSettings.application,
      id: '',
      load: {
        active: alertSettings.elementLoad.active,
        threshold: alertSettings.elementLoad.threshold,
        period: alertSettings.elementLoad.period
      }
    }
  }

  return ({
    type: QmonActions.QMON_ELEMENT_ALERT_SETTINGS_GET,
    alertSettings: transformedAlertSettings
  })
};

const updateElementAlertSettings = (alertSettings) => {
  return ({
    type: QmonActions.QMON_ELEMENT_ALERT_SETTINGS_UPDATE,
    alertSettings
  })
};

const createElement = (element: IElement) => {
  return ({
    type: QmonActions.QMON_ELEMENT_CREATE,
    element
  })
};

const deleteElement = (elementId) => {
  return ({
    type: QmonActions.QMON_ELEMENT_DELETE,
    elementId
  })
};

const updateElement = (element: IElement) => {
  return ({
    type: QmonActions.QMON_ELEMENT_UPDATE,
    element
  })
};

const returnContent = (content) => {
  return ({
    type: QmonActions.QMON_CONTENT_LIST,
    content: content.contentvalidations
  })
};

const returnSummaries = (fetchedSummaries) => {
  let summaries = null

  if (fetchedSummaries.summaries.length > 0) {
    summaries = {...fetchedSummaries.summaries[0]}
  }

  return ({
    type: QmonActions.QMON_SUMMARIES_GET,
    summaries
  })
};

const returnDiagnostics = (fetchedDiagnostics) => {
  let diagnostics = null
  if (fetchedDiagnostics.length > 0) {
    diagnostics = fetchedDiagnostics
  }
  return ({
    type: QmonActions.QMON_DIAGNOSTICS,
    diagnostics
  })
}

const returnValidationAlertSettings = (alertSettings) => {
  let transformedAlertSettings = ELEMENT_DEFAULT_SETTINGS

  if (alertSettings['contentvalidations/settings'].length > 0) {
    alertSettings = {...alertSettings['contentvalidations/settings'][0]}

    transformedAlertSettings = {
      application: alertSettings.application,
      load: {
        active: alertSettings.validity.active,
        threshold: alertSettings.validity.threshold,
        period: alertSettings.validity.period
      },
      id: ''
    }
  }

  return ({
    type: QmonActions.QMON_VALIDATION_ALERT_SETTINGS_GET,
    alertSettings: transformedAlertSettings
  })
};

const updateValidationAlertSettings = (alertSettings) => {
  return ({
    type: QmonActions.QMON_VALIDATION_ALERT_SETTINGS_UPDATE,
    alertSettings
  })
};

const createContent = (validation: IContentValidation) => {
  return ({
    type: QmonActions.QMON_CONTENT_CREATE,
    validation
  })
};

const deleteContent = (validationId) => {
  return ({
    type: QmonActions.QMON_CONTENT_DELETE,
    validationId
  })
};

const updateContent = (validation: IContentValidation) => {
  return ({
    type: QmonActions.QMON_CONTENT_UPDATE,
    validation
  })
};
const subscribeToEventSuccess = (subscription: ISubscription) => {
  return ({
    type: QmonActions.QMON_EVENT_SUBSCRIBE,
    subscription
  })
};

const unsubscribeToEventSuccess = (subscriptionId) => {
  return ({
    type: QmonActions.QMON_EVENT_UNSUBSCRIBE,
    subscriptionId
  })
};
const subscribeToElementSuccess = (subscription: ISubscription) => {
  return ({
    type: QmonActions.QMON_ELEMENT_SUBSCRIBE,
    subscription
  })
};

const unsubscribeToElementSuccess = (subscriptionId) => {
  return ({
    type: QmonActions.QMON_ELEMENT_UNSUBSCRIBE,
    subscriptionId
  })
};
const subscribeToValidationSuccess = (subscription: ISubscription) => {
  return ({
    type: QmonActions.QMON_VALIDATION_SUBSCRIBE,
    subscription
  })
};

const unsubscribeToValidationSuccess = (subscriptionId) => {
  return ({
    type: QmonActions.QMON_VALIDATION_UNSUBSCRIBE,
    subscriptionId
  })
};



// Grouped action creators as an object so it's handier to use bindActionCreators
export const QmonActionCreators = {
  retrieveAlerts: (alertEntityType = AlertEntityType.ALL) => {
    const selectedWebsite = store.getState().qmon.selected.website;
    if(!selectedWebsite) {
      return store.dispatch(returnAlerts([]))
    }

    return dispatch => {
      qmonInstance.get(`alerts?type=${alertEntityType}&websiteId=${selectedWebsite.id}`)
        .then(result => dispatch(returnAlerts(result.data.alerts)))
        .catch(error => console.error(error));
    }
  },
  createCompany: (company) => {
    return dispatch => {
      qmonInstance.post(`/admin/companies`, {
        company
      }).then((result) => {
        dispatch(createCompany(company))
        return dispatch(QmonActionCreators.retrieveCompanies()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editCompany: (company) => {
    return dispatch => {
      qmonInstance.put(`/admin/companies/${company.id}`, {
        company
      }).then((result) => {
        dispatch(updateCompany({...company}))
        return dispatch(QmonActionCreators.retrieveCompanies()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeCompany: (companyId) => {
    return dispatch => {
      qmonInstance.delete(`/admin/companies/${companyId}`).then((result) => {
        dispatch(deleteCompany(companyId))
        return dispatch(QmonActionCreators.retrieveCompanies()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveCompanies: () => {
    return dispatch => {
      qmonInstance.get(User.get().rolesFor('qa').includes('admin') ? `admin/companies` :`companies`)
        .then(result => dispatch(returnCompanies(result.data)))
        .catch(error => console.error(error));
    }
  },
  retrieveAdobeVariables: () => {
    return dispatch => {
      qmonInstance.get('/adobevariables')
      .then((result) => {
        return dispatch(returnAdobeVariables(result.data.adobevariables))
      }).catch((error) => {
        showMessage('There was a problem while retrieving the adobe variables')
      });
    }
  },
  retrieveValidationOperators: () => {
    return dispatch => {
      qmonInstance.get('/validationoperators')
      .then((result) => {
        return dispatch(returnValidationOperators(result.data.validationoperators))
      }).catch((error) => {
        showMessage('There was a problem while retrieving the validation operators')
      });
    }
  },
  createDomain: (domain) => {
    return dispatch => {
      qmonInstance.post(`/admin/domains`, {
        domain
      }).then((result) => {
        dispatch(createDomain(domain))
        return dispatch(QmonActionCreators.retrieveDomains()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editDomain: (domain) => {
    return dispatch => {
      qmonInstance.put(`/admin/domains/${encodeURIComponent(domain.id)}`, {
        domain
      }).then((result) => {
        dispatch(updateDomain({...domain}))
        return dispatch(QmonActionCreators.retrieveDomains()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeDomain: (domainId) => {
    return dispatch => {
      qmonInstance.delete(`/admin/domains/${encodeURIComponent(domainId)}`).then((result) => {
        dispatch(deleteDomain(domainId))
        return dispatch(QmonActionCreators.retrieveDomains()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveDomains: () => {
    const selectedCompany = store.getState().qmon.selected.company;
    if(!selectedCompany) {
      return store.dispatch(returnDomains([]))
    }

    return dispatch => {
      qmonInstance.get(`/admin/domains?client=${selectedCompany.id}`)
        .then(result => dispatch(returnDomains(result.data["admin/domains"])))
        .catch(error => console.error(error));
    }
  },
  resendInvitation: (invitation) => {
    return dispatch => {
      qmonInstance.put(`/admin/invitations/${invitation.id}`, {
        invitation
      }).then((result) => {
        return dispatch(updateInvitation(invitation))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeInvitation: (invitationId) => {
    return dispatch => {
      qmonInstance.delete(`/admin/invitations/${invitationId}`).then((result) => {
        dispatch(deleteInvitation(invitationId))
        return dispatch(QmonActionCreators.retrieveInvitations()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveInvitations: () => {
    return dispatch => {
      qmonInstance.get('admin/invitations')
        .then(result => dispatch(returnInvitations(result.data)))
        .catch(error => console.error(error));
    }
  },
  createUser: (user) => {
    return dispatch => {
      qmonInstance.post('/admin/invitations', {
        user
      }).then((result) => {
        dispatch(createUser(user))
        return dispatch(QmonActionCreators.retrieveUsers()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editUser: (user) => {
    return dispatch => {
      qmonInstance.put(`/admin/users/${user.id}`, {
        user
      }).then((result) => {
        dispatch(updateUser(user))
        return dispatch(QmonActionCreators.retrieveUsers()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeUser: (userId) => {
    return dispatch => {
      qmonInstance.delete(`/admin/users/${userId}`).then((result) => {
        dispatch(deleteUser(userId))
        return dispatch(QmonActionCreators.retrieveUsers()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveUsers: () => {
    return dispatch => {
      qmonInstance.get('admin/users')
        .then(result => dispatch(returnUsers(result.data)))
        .catch(error => console.error(error));
    }
  },
  setUserRole: (user) => {
    return dispatch => {
      qmonInstance.put(`/admin/users/setrole/${user.id}`, {
        roleId: user.roleId
      }).then((result) => {
        return dispatch(setRoleUser(user))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  changePassword: (passwordChange) => {
    return dispatch => {
      qmonInstance.post('auth-token/passwordChange', {
        passwordChange
      }).then((result) => {
        dispatch(showMessage("Password updated succesfully"))
        return dispatch(updatePassword(result.data))
      }).catch((error) => {
        dispatch(showMessage("Sorry! There was a problem updating your password"))
        console.log(error)
      });
    }
  },
  editUserProfile: (user) => {
    return dispatch => {
      qmonInstance.put(`users/${user.id}`, {
        user
      }).then((result) => {
        dispatch(showMessage("User profile updated succesfully"))
        return dispatch(updateUserProfile(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveUserProfile: () => {
    return dispatch => {
      qmonInstance.get('/users')
        .then(result => dispatch(returnUserProfile(result.data)))
        .catch(error => console.error(error));
    }
  },
  activeWebsite: (website: IWebsite) => async dispatch => {
    await qmonInstance.patch(`/admin/websites/switch/${website.id}`, {
      collectionActive: website.collectionActive
    })
    await dispatch(activeWebsiteAction(website))

    const enabled = website.collectionActive ? 'enabled' : 'disabled'
    dispatch(showMessage(`Data collection ${enabled} for website "${website.name}"`))

    const websites = await QmonActionCreators.retrieveWebsites()
    dispatch(websites)

    return websites
  },
  createWebsite: (website: IWebsite) => async dispatch => {
    await qmonInstance.post('/admin/websites', { website})
    dispatch(createWebsiteAction(website))
    dispatch(QmonActionCreators.retrieveDomains())
    const websites = await QmonActionCreators.retrieveWebsites()
    dispatch(websites)
    return websites
  },
  editWebsite: (website: IWebsite) => async dispatch => {
    await qmonInstance.put(`/admin/websites/${website.id}`, {website})
    dispatch(updateWebsite({...website}))
    dispatch(QmonActionCreators.retrieveDomains())
    const websites = await QmonActionCreators.retrieveWebsites()
    dispatch(websites)
    return websites
  },
  removeWebsite: (websiteId) => async dispatch => {
    await qmonInstance.delete(`/admin/websites/${websiteId}`)
    dispatch(deleteWebsite(websiteId))
    dispatch(QmonActionCreators.retrieveDomains())
    dispatch(showMessage("The website has been deleted"))
    const websites = await QmonActionCreators.retrieveWebsites() // refresh data from server
    dispatch (websites)

    return websites
  },
  retrieveWebsites: async () => {
    return async dispatch => {
      try {
        const result = await qmonInstance.get('websites')
        dispatch(returnWebsites(result.data))
        return result.data
      } catch(error) {
        console.error('qa retrieveWebsites error', error)
      }
    }
  },
  createEvent: (event) => {
    if (event.type === 'dcf' && event.parentId) {
      event = {...event, dcfEvent:'event'} // DEQ (formerly DCF) subevents need this set for some reason
    }

    return dispatch => {
      qmonInstance.post(`/events`, {
        event
      }).then((result) => {
        console.log(result)
        dispatch(createEvent(event))
        return dispatch(QmonActionCreators.retrieveEvents()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editEvent: (event) => {
    return dispatch => {
      qmonInstance.put(`/events/${event.id}`, {
        dcfEvent: event.dcfEvent,
        eventQueue: event.eventQueue,
        matching: event.matching,
        name: event.name
      }).then((result) => {
        dispatch(createEvent(event))
        return dispatch(QmonActionCreators.retrieveEvents()) // refresh data from server
      }).catch((error) => {
        dispatch(showMessage("There was a problem updating the event: " + error))
      });
    }
  },
  removeEvent: (eventId) => {
    return dispatch => {
      qmonInstance.delete(`/events/${eventId}`).then((result) => {
        console.log(result)
        dispatch(deleteEvent(eventId))
        return dispatch(QmonActionCreators.retrieveEvents()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveEvents: () => {
    const website = store.getState().qmon.selected.website;

    if (!website) {
      store.dispatch(showLoading())
      return store.dispatch(returnEvents({events: []}))
    } else {
      return dispatch => {
        qmonInstance.get(`events?subscriptions=true&websiteId=${website.id}`)
          .then(result => {
            dispatch(hideLoading())
            return dispatch(returnEvents(result.data))
          })
          .catch(error => {
            dispatch(hideLoading())
            console.error(error)
          });
      }
    }
  },
  retrieveEventAlertSettings: (eventId) => {
    return dispatch => {
      qmonInstance.get(`/events/settings/${eventId}`).then((result) => {
        return dispatch(returnEventAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editEventAlertSettings: (alertSettings) => {
    return dispatch => {
      qmonInstance.put(`/events/settings/${alertSettings.id}`, {
        alertSettings
      }).then((result) => {
        dispatch(showMessage("Event alert settings updated succesfully"))
        return dispatch(updateEventAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  createElement: (element) => {
    return dispatch => {
      qmonInstance.post(`/elements`, {
        ...element, elementLoad: null, subscription: null // remove elementLoad and subscription data as it's not needed and makes the request fail
      }).then((result) => {
        dispatch(createElement(element))
        return dispatch(QmonActionCreators.retrieveElements()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editElement: (element) => {
    return dispatch => {
      qmonInstance.put(`/elements/${element.id}`, {
        element
      }).then((result) => {
        dispatch(updateElement({...element}))
        return dispatch(QmonActionCreators.retrieveElements()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeElement: (elementId) => {
    return dispatch => {
      qmonInstance.delete(`/elements/${elementId}`).then((result) => {
        dispatch(deleteElement(elementId))
        return dispatch(QmonActionCreators.retrieveElements()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveElements: () => {
    const website = store.getState().qmon.selected.website;

    if (!website) {
      return store.dispatch(returnElements({elements: []}))
    } else {
      return dispatch => {
        qmonInstance.get(`elements?websiteId=${website.id}&figutes=true&subscriptions=true`)
          .then(result => dispatch(returnElements(result.data)))
          .catch(error => console.error(error));
      }
    }
  },
  retrieveElementAlertSettings: (elementId) => {
    return dispatch => {
      qmonInstance.get(`/elements/settings/${elementId}`).then((result) => {
        return dispatch(returnElementAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editElementAlertSettings: (alertSettings) => {
    return dispatch => {
      qmonInstance.put(`/elements/settings/${alertSettings.id}`, {
        alertSettings
      }).then((result) => {
        dispatch(showMessage("Element alert settings updated succesfully"))
        return dispatch(updateElementAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  createContent: (contentvalidation) => {
    return dispatch => {
      qmonInstance.post('/contentvalidations', {
        contentvalidation: { // the object fields must be in this specific order for adobe analytics content validations to work
          displayName: contentvalidation.displayName,
          websiteId: contentvalidation.websiteId,
          caseSensitive: contentvalidation.caseSensitive,
          eventId: contentvalidation.eventId,
          elementId: contentvalidation.elementId,
          elementType: contentvalidation.elementType,
          elementName: contentvalidation.elementName,
          eventType: contentvalidation.eventType,
          eventName: null,
          jsObjectName: contentvalidation.jsObjectName,
          jsObjectCheckType: contentvalidation.jsObjectCheckType,
          tagEventType: contentvalidation.tagEventType,
          tagType: contentvalidation.tagType,
          variable: contentvalidation.variable,
          numValue: contentvalidation.numValue,
          parameter: contentvalidation.parameter,
          operator: contentvalidation.operator,
          equal: contentvalidation.equal,
          matching: contentvalidation.matching,
          values: contentvalidation.values,
          ranges: contentvalidation.ranges
        }
      }).then((result) => {
        dispatch(createContent(contentvalidation))
        return dispatch(QmonActionCreators.retrieveContent()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editContent: (contentvalidation) => {
    return dispatch => {
      qmonInstance.put(`/contentvalidations/${contentvalidation.id}`, {
        contentvalidation: { // the object fields must be in this specific order for adobe analytics content validations to work
          displayName: contentvalidation.displayName,
          websiteId: contentvalidation.websiteId,
          caseSensitive: contentvalidation.caseSensitive,
          eventId: contentvalidation.eventId,
          elementId: contentvalidation.elementId,
          elementType: contentvalidation.elementType,
          elementName: contentvalidation.elementName,
          eventType: contentvalidation.eventType,
          eventName: null,
          jsObjectName: contentvalidation.jsObjectName,
          jsObjectCheckType: contentvalidation.jsObjectCheckType,
          tagEventType: contentvalidation.tagEventType,
          tagType: contentvalidation.tagType,
          variable: contentvalidation.variable,
          numValue: contentvalidation.numValue,
          parameter: contentvalidation.parameter,
          operator: contentvalidation.operator,
          equal: contentvalidation.equal,
          matching: contentvalidation.matching,
          values: contentvalidation.values,
          ranges: contentvalidation.ranges
        }
      }).then((result) => {
        dispatch(updateContent(contentvalidation))
        return dispatch(QmonActionCreators.retrieveContent()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  removeContent: (validationId) => {
    return dispatch => {
      qmonInstance.delete(`/contentvalidations/${validationId}`).then((result) => {
        console.log(result)
        dispatch(deleteContent(validationId))
        return dispatch(QmonActionCreators.retrieveContent()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  retrieveContent: () => {
    const website = store.getState().qmon.selected.website;

    if (!website) {
      return store.dispatch(returnContent({contentvalidations: []}))
    } else {
      return dispatch => {
        qmonInstance.get(`contentvalidations?subscriptions=true&websiteId=${website.id}`)
          .then(result => dispatch(returnContent(result.data)))
          .catch(error => console.error(error));
      }
    }
  },
  retrieveSummaries: (companyId) => {
    return dispatch => {
      qmonInstance.get(`/summaries/${companyId}`).then((result) => {
        return dispatch(returnSummaries(result.data))
      }).catch(() => {
        dispatch(showMessage("There was a problem getting the summary from the server"))
      });
    }
  },
  retrieveDiagnostics: (type, contentId, eventId) => {
    return dispatch => {
      qmonInstance.get(`${type}/diagnostics/${contentId}?eventId=${eventId}`).then((result) => {
        return dispatch(returnDiagnostics(result.data[`${type}/diagnostics`]))
      }).catch(() => {
        dispatch(showMessage("There was a problem getting diagnostics from the server"))
      });
    }
  },
  retrieveValidationAlertSettings: (id) => {
    return dispatch => {
      qmonInstance.get(`/contentvalidations/settings/${id}`).then((result) => {
        return dispatch(returnValidationAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  editValidationAlertSettings: (alertSettings) => {
    return dispatch => {
      qmonInstance.put(`/contentvalidations/settings/${alertSettings.id}`, {
        alertSettings
      }).then((result) => {
        dispatch(showMessage("Validation alert settings updated succesfully"))
        return dispatch(updateValidationAlertSettings(result.data))
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  subscribeToAlert: (subscriptionId) => {
    return dispatch => {
      qmonInstance.put(`/subscriptions/${subscriptionId}`, {}).then(() => {
        dispatch(showMessage("Subscribed to Alert"))
        return dispatch(QmonActionCreators.retrieveAlerts()) // refresh data from server
      }).catch(() => {
        dispatch(showMessage("There was a problem subscribing to Alert"))
        return dispatch(QmonActionCreators.retrieveAlerts()) // refresh data from server
      });
    }
  },
  unsubscribeToAlert: (subscriptionId) => {
    return dispatch => {
      qmonInstance.delete(`/subscriptions/${subscriptionId}`).then(() => {
        dispatch(showMessage("Unsubscribed from Alert"))
        return dispatch(QmonActionCreators.retrieveAlerts()) // refresh data from server
      }).catch(() => {
        dispatch(showMessage("There was a problem unsubscribing from Alert"))
        return dispatch(QmonActionCreators.retrieveAlerts()) // refresh data from server
      });
    }
  },
  subscribeToEvent: (subscription) => {
    return dispatch => {
      qmonInstance.post('/subscriptions', {
        subscription
      }).then((result) => {
        dispatch(subscribeToEventSuccess(subscription))
        return dispatch(QmonActionCreators.retrieveEvents()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  unsubscribeToEvent: (subscriptionId) => {
    return dispatch => {
      qmonInstance.delete(`/subscriptions/${subscriptionId}`).then((result) => {
        dispatch(unsubscribeToEventSuccess(subscriptionId))
        return dispatch(QmonActionCreators.retrieveEvents())
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  subscribeToElement: (subscription) => {
    return dispatch => {
      qmonInstance.post('/subscriptions', {
        subscription
      }).then((result) => {
        dispatch(subscribeToElementSuccess(subscription))
        return dispatch(QmonActionCreators.retrieveElements()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  unsubscribeToElement: (subscriptionId) => {
    return dispatch => {
      qmonInstance.delete(`/subscriptions/${subscriptionId}`).then((result) => {
        dispatch(unsubscribeToElementSuccess(subscriptionId))
        return dispatch(QmonActionCreators.retrieveElements()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  subscribeToValidation: (subscription) => {
    return dispatch => {
      qmonInstance.post('/subscriptions', {
        subscription
      }).then((result) => {
        dispatch(subscribeToValidationSuccess(subscription))
        return dispatch(QmonActionCreators.retrieveContent()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  subscribeNonUsers: (subscriptionId, nonUsersEmails) => {
    return dispatch => {
      qmonInstance.patch(`subscriptions/nonusers/${subscriptionId}`, {
        nonUsersEmails
      }).then((result) => {
        return dispatch(showMessage("Non users subscriptions modified succesfully"))
      }).catch((error) => {
        return dispatch(showMessage("There was an error while modifying the subscriptions"))
      });
    }
  },

  unsubscribeToValidation: (subscriptionId) => {
    return dispatch => {
      qmonInstance.delete(`/subscriptions/${subscriptionId}`).then((result) => {
        dispatch(unsubscribeToValidationSuccess(subscriptionId))
        return dispatch(QmonActionCreators.retrieveContent()) // refresh data from server
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  selectCompany: (company) => ({
      type: QmonActions.QMON_SELECT_COMPANY,
      company
    }),
  selectWebsite: (website) => ({
    type: QmonActions.QMON_SELECT_WEBSITE,
    website
  }),
  selectWebsiteById: (websiteId) => ({
    type: QmonActions.QMON_SELECT_WEBSITE_BY_ID,
    websiteId
  })
}
