import {SET_APP_BREADCRUMB, SET_ON_INFO_CLICK, TOGGLE_SIDEBAR, HIDE_SIDEBAR} from '../actions'
import { AppPropertiesAction } from '../actions/appProperties';


const initialState = {
  customBreadCrumb: null,
  onInfoClick: null,
  sidebar: true,
};

export type AppPropertyState = typeof initialState

const appProperties = (state: AppPropertyState = initialState, action: AppPropertiesAction) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...initialState,
        sidebar: !state.sidebar
      }
    case HIDE_SIDEBAR:
      return {
        ...initialState,
        sidebar: false
      }
    case SET_APP_BREADCRUMB:
      return {
        ...initialState,
        customBreadCrumb: action.data
      }
    case SET_ON_INFO_CLICK:
      return {
        ...initialState,
        onInfoClick: action.data
      }
    default:
      return state;
  }
};

export default appProperties;
