import {CHANGE_COMPANY, COMPANIES_LIST,
        USER_LOGIN, USER_SETTINGS,
        USER_UPDATE} from '../actions';

import {QmonActions} from '../actions/qmon'

import {ICompany} from './companies'
import {IRole} from './rights'


export interface ISettingsState {
  user_email: string | null,
  user_firstname: string | null,
  user_lastname: string | null,
  user_roles: IRole[],
  companies: ICompany[],
  user_id: number | null,
  company_name: string | null,
  company_id: string | null,
}

const initialState: ISettingsState = {
  user_email: null,
  user_firstname: null,
  user_lastname: null,
  user_roles: [
    {
      id: 4,
      name: "Admin"
    }
  ],
  companies: [
    {
      sfId: "0015700001lqaoFAAQ",
      name: "Datastreams.io Demo account"
    }
  ],
  user_id: null,
  company_name: null,
  company_id: null
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case USER_SETTINGS:
    case USER_LOGIN:
      return {
        ...state,
        user_email: action.user_email,
        user_firstname: action.user_firstname,
        user_lastname: action.user_lastname,
        user_roles: action.user_roles,
        user_companies: action.user_companies,
        user_id: action.user_id
      };
    case QmonActions.QMON_USER_PROFILE_GET:
      return {
        ...state,
        user_email: action.users.email,
        user_firstname: action.users.firstName,
        user_lastname: action.users.lastName,
        user_id: action.users.id,
        user_roles: [action.users.roleId]
      }
    case USER_UPDATE:
      return {
        ...state,
        user_email: action.user_email,
        user_firstname: action.user_firstname,
        user_lastname: action.user_lastname
      };
    case COMPANIES_LIST:
    case CHANGE_COMPANY:
      return {
        ...state,
        company_name: action.company_name,
        company_id: action.company_id
      };
    default:
      return state;
  }
};

export default settings;
