import * as React from "react";
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Header from '../../components/header';
import {MenuItem, Sidebar, SidebarLogo, SubmenuItem, SubmenuItemExternal} from '../../components/sidebar';
import LoadingIndicator from '../../components/loading-indicator';
import {hideMessage} from '../../actions/message';
import { toggleSidebar } from '../../actions/appProperties';
import BreadCrumbs from '../../components/breadcrumbs';
import Image from '../../datastreams_icon_small.png';
import Authorize from '../../components/authorize'
import ErrorBoundary from '../../components/error-boundary'
import {BODY_COLOR, DARK_TEXT_COLOR, SIDEBAR_WIDTH, SIDEBAR_WIDTH_SMALL} from '../../main.style';
import { injectGlobal } from 'emotion';
import UserModel, { IUser } from '../../models/users/user'
import styled from 'react-emotion';
import {
  faBell,
  faCalendar,
  faCog,
  faCube,
  faFileAlt,
  faTv,
  faTh,
  faUsers,
  faPlug,
} from "@fortawesome/free-solid-svg-icons"
import { userLogin } from "../../actions/users";
import { AppPropertyState } from '../../reducers/appProperties';
import { IMessage } from '../../reducers/message';

const StreamsDashboard = React.lazy(() => import ( '../streams/dashboard'))
const StreamsDomains = React.lazy(() => import ( '../streams/domains'))
const StreamEdit = React.lazy(() => import ( '../streams/edit'))
const StreamSourceModelEdit = React.lazy(() => import ( '../streams/operations/edit'))
const StreamConnections = React.lazy(() => import ( '../streams/connections'))
const StreamConnectionsNewEdit = React.lazy(() => import ( '../streams/connections/new-edit'))
const StreamConnectionSchema = React.lazy(() => import ('../streams/connections/schema'))
const StreamProjects = React.lazy(() => import ('../streams/projects'))
const StreamProjectsNewEdit = React.lazy(() => import ('../streams/projects/new-edit'))
const StreamUsers = React.lazy(() => import ('../streams/users'))
const StreamUsersNewEdit = React.lazy(() => import ('../streams/users/new-edit'))
const StreamSegments= React.lazy(() => import ( '../streams/segments'))
const StreamSegmentsNewEdit = React.lazy(() => import ( '../streams/segments/new-edit'))

const Login = React.lazy(() => import('../management/login/login'))
const Register = React.lazy(() => import('../management/login/register'))
const PasswordReset = React.lazy(() => import('../management/login/password-reset'))
const Documentation = React.lazy(() => import('../management/documentation/documentation'))
const Domains = React.lazy(() => import('../management/domains/domains'))
const User = React.lazy(() => import('../management/users/user'))

const Dashboard = React.lazy(() => import('../governance/dashboard/dashboard'))
const Policies = React.lazy(() => import('../governance/policies/overview'))

const QmonAlerts = React.lazy(() => import('../qmon/alerts'))
const QmonWebsites = React.lazy(() => import('../qmon/websites'))
const QmonEvents = React.lazy(() => import('../qmon/events'))
const QmonEventGraph = React.lazy(() => import('../qmon/event-graph'))
const QmonElements = React.lazy(() => import('../qmon/elements'))
const QmonElementGraph = React.lazy(() => import('../qmon/elements-graph'))
const QmonElementDiagnostics = React.lazy(() => import('../qmon/elements-diagnostics'))
const QmonContent = React.lazy(() => import('../qmon/content'))
const QmonContentGraph = React.lazy(() => import('../qmon/content-graph'))
const QmonContentDiagnostics = React.lazy(() => import('../qmon/content-diagnostics'))
const QmonCompanies = React.lazy(() => import('../qmon/companies'))
const QmonDomains = React.lazy(() => import('../qmon/domains'))
const QmonInvitations = React.lazy(() => import('../qmon/invitations'))
const QmonDashboard = React.lazy(() => import('../qmon/overview'))
const QmonSettings = React.lazy(() => import('../qmon/settings'))
const QmonUsers = React.lazy(() => import('../qmon/users'))

const Variables = React.lazy(() => import('../streams/variables'))

// using const prevents base64 inline Uri for smaller files, when using import | sjk
const logoUrl = '/datastreams_icon.png';


injectGlobal`
  body {
    margin: 0;
    padding: 0;
    background-color: ${BODY_COLOR};
    font-family: 'Source Sans Pro', sans-serif;
    color: ${DARK_TEXT_COLOR};
    overflow: hidden;
    max-width: 100vw;
  }
`

interface IEmotionSidebarProp {
  sidebarBig: boolean
}

const BodyContent = styled('div')<IEmotionSidebarProp>(
  {
    float: 'right',
    height: '100vh',
    overflowY: 'scroll',
    transition: '0.2s',
  },
  props => ({
    width: props.sidebarBig ? `calc(100vw - ${SIDEBAR_WIDTH})` : `calc(100vw - ${SIDEBAR_WIDTH_SMALL})`,
  })
)

const Content = styled('div')(
  {
    padding: '20px'
  }
)

const BodySideBar = styled('div')<IEmotionSidebarProp>(
  {
    float: 'left',
  },
  props => ({
    width: props.sidebarBig ? `${SIDEBAR_WIDTH}` : `${SIDEBAR_WIDTH_SMALL}`,
  })
)


interface IProps {
  location: Location
  user: UserModel
  message: IMessage
  userLogin: (user: IUser, path?: string) => void
  app_properties: AppPropertyState
  toggleSidebar: () => void
  hideMessage: () => void
  selectedCompany: any
}

class App extends React.Component<IProps> {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: window.innerWidth > 900
    };
  }

  componentDidMount() {
    this.forceLogin(document.location.pathname)
  }

  forceLogin(path='/login'){
    const user = UserModel.get()
    if(!this.props.user.authorized() &&
      !this.props.location.pathname.includes('login') &&
      !this.props.location.pathname.includes('register/invitation') &&
      !this.props.location.pathname.includes('password-reset')){
      this.props.userLogin(user, path)
    }
  }

  render() {
    return (<>
        {this.props.user.authorized() ?
            <>
              <BodySideBar sidebarBig={this.props.app_properties.sidebar}>
                <ErrorBoundary>
                  <Sidebar>
                    <SidebarLogo
                      src={this.props.app_properties.sidebar ? logoUrl : Image}
                      alt="logo"
                    />
                    <Authorize module="privacy_and_compliance">
                      <MenuItem exact link="/governance" name="PRIVACY & COMPLIANCE" showName={this.props.app_properties.sidebar}>
                        <SubmenuItem link="/governance/dashboard" name="DASHBOARD" icon={faTv}
                                    showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/governance/policies" name="POLICIES" icon={faTh}
                                    showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/streams/connections" name="CONNECTIONS" icon={faPlug}
                                      showName={this.props.app_properties.sidebar}/>
                      </MenuItem>
                    </Authorize>

                    <Authorize module="operator">
                      <MenuItem exact link="/streams" name="OPERATOR" showName={this.props.app_properties.sidebar}>
                      <SubmenuItem link="/streams/dashboard" name="DASHBOARD" icon={faTv}
                                      showName={this.props.app_properties.sidebar}/>
                      <SubmenuItem link="/streams/connections" name="CONNECTIONS" icon={faPlug}
                                      showName={this.props.app_properties.sidebar}/>
                      </MenuItem>
                    </Authorize>

                    <Authorize module="qa">
                      <MenuItem exact link="/quality-assurance" name="QUALITY ASSURANCE" showName={this.props.app_properties.sidebar}>
                        <SubmenuItem link="/quality-assurance/dashboard" name="DASHBOARD" icon={faTv} showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/quality-assurance/alerts" name="ALERTS" icon={faBell} showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/quality-assurance/events" name="EVENTS" icon={faCalendar} showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/quality-assurance/elements" name="ELEMENTS" icon={faCube} showName={this.props.app_properties.sidebar}/>
                        <SubmenuItem link="/quality-assurance/content" name="CONTENT" icon={faFileAlt} showName={this.props.app_properties.sidebar}/>
                        <Authorize module="qa" roles={['admin','engineer']}>
                          <SubmenuItem link="/quality-assurance/settings" name="SETTINGS" icon={faCog} showName={this.props.app_properties.sidebar}/>
                        </Authorize>
                      </MenuItem>
                    </Authorize>

                    {this.props.selectedCompany ? <>
                    <Authorize module={["operator", "privacy_and_compliance", "qa"]} roles={[`administrators`]}>
                      <MenuItem exact link="/datastreams" name="MANAGE" showName={this.props.app_properties.sidebar}>
                            <SubmenuItemExternal link={"https://app.datastreams.io/companies/"+this.props.selectedCompany.id+"/"} target="_blank" name="SETTINGS" icon={faCog} showName={this.props.app_properties.sidebar}/>
                            <Authorize module="operator" roles={[`administrators_company_${this.props.selectedCompany.id}`]}>
                              <SubmenuItem link="/streams/users" name="USERS" icon={faUsers}  showName={this.props.app_properties.sidebar}/>
                            </Authorize>
                      </MenuItem>
                    </Authorize>
                      </>
                        : <></>}

                  </Sidebar>
                </ErrorBoundary>
              </BodySideBar>

              <BodyContent sidebarBig={this.props.app_properties.sidebar}>
                <Header
                  title=""
                  toggleSidebar={this.props.toggleSidebar}/>

                <ErrorBoundary>
                  <BreadCrumbs/>
                </ErrorBoundary>

                <Content>
                  <ErrorBoundary>
                    <Switch>
                      <React.Suspense fallback={<span>Loading...</span>}>
                        <Route
                          path="/governance/dashboard"
                          component={Dashboard}/>
                        <Route
                          path="/governance/policies"
                          component={Policies}/>
                        <Route
                          path="/domains"
                          component={Domains}/>
                        <Route
                          path="/user"
                          component={User}/>
                        <Route
                          path="/documentation"
                          component={Documentation}/>
                        <Route
                          path="/quality-assurance/alerts"
                          component={QmonAlerts}/>
                        <Route
                          path="/quality-assurance/events/:eventId/graph"
                          component={QmonEventGraph}/>
                        <Route
                          exact path="/quality-assurance/events"
                          component={QmonEvents}/>
                        <Route
                          path="/quality-assurance/elements/:elementId/diagnostics"
                          component={QmonElementDiagnostics}/>
                        <Route
                          path="/quality-assurance/elements/:elementId/graph"
                          component={QmonElementGraph}/>
                        <Route
                          exact path="/quality-assurance/elements"
                          component={QmonElements}/>
                        <Route
                          path="/quality-assurance/content/:contentId/diagnostics"
                          component={QmonContentDiagnostics}/>
                        <Route
                          path="/quality-assurance/content/:contentId/graph"
                          component={QmonContentGraph}/>
                        <Route
                          exact path="/quality-assurance/content"
                          component={QmonContent}/>
                        <Route
                          path="/quality-assurance/dashboard"
                          component={QmonDashboard}/>
                        <Route
                          path="/streams/dashboard"
                          component={StreamsDashboard}/>
                        <Route
                          path="/streams/variables"
                          component={Variables}
                        />
                        <Route
                          path="/streams/projects/:projectId/edit"
                          component={StreamProjectsNewEdit}/>
                        <Route
                          path="/streams/projects/new"
                          component={StreamProjectsNewEdit}/>
                        <Route
                          exact path="/streams/projects"
                          component={StreamProjects}/>
                        <Route
                          exact path="/streams/domains"
                          component={StreamsDomains}/>
                        <Route
                          path="/streams/users/:userId/edit"
                          component={StreamUsersNewEdit}/>
                        <Route
                          path="/streams/users/new"
                          component={StreamUsersNewEdit}/>
                        <Route
                          exact path="/streams/users"
                          component={StreamUsers}/>
                        <Route
                          path="/streams/connections/:connectionId/edit"
                          component={StreamConnectionsNewEdit}/>
                        <Route
                          path="/streams/connections/new"
                          component={StreamConnectionsNewEdit}/>
                        <Route
                          path="/streams/connections/:connectionId/schema"
                          component={StreamConnectionSchema}/>
                        <Route
                          exact path="/streams/connections"
                          component={StreamConnections}/>
                        <Route
                          path="/labels-and-segments/segments/:segmentId/edit"
                          component={StreamSegmentsNewEdit}/>
                        <Route
                          path="/labels-and-segments/segments/new"
                          component={StreamSegmentsNewEdit}/>
                        <Route
                          exact path="/labels-and-segments/segments"
                          component={StreamSegments}/>
                        <Route
                          path="/streams/overview/:id/operations/edit/:opid"
                          component={StreamSourceModelEdit}/>
                        <Route
                          path="/streams/overview/:id/operations/edit"
                          component={StreamSourceModelEdit}/>
                        <Route
                          path="/streams/overview/:id/edit"
                          component={StreamEdit}/>
                        <Authorize module="qa" roles={['admin']}>
                          <Route
                            path="/quality-assurance/settings/companies"
                            component={QmonCompanies}/>
                          <Route
                            path="/quality-assurance/settings/domains"
                            component={QmonDomains}/>
                          <Route
                            path="/quality-assurance/settings/invitations"
                            component={QmonInvitations}/>
                        </Authorize>
                        <Authorize module="qa" roles={['admin','engineer']}>
                          <Route
                            exact path="/quality-assurance/settings"
                            component={QmonSettings}/>
                          <Route
                            path="/quality-assurance/settings/users"
                            component={QmonUsers}/>
                          <Route
                            path="/quality-assurance/settings/websites"
                            component={QmonWebsites}/>
                        </Authorize>
                      </React.Suspense>
                    </Switch>
                  </ErrorBoundary>
                </Content>
              </BodyContent>
            </>
          :
          <Switch>
            <React.Suspense fallback={<span>Loading...</span>}>
              <Route
                path="/register/invitation/:invitationHash"
                component={Register}/>
              <Route
                path="/password-reset/:token"
                component={PasswordReset}/>
              <Route
                exact={true}
                path="/login"
                component={Login}/>
            </React.Suspense>
          </Switch>
        }
        <MuiThemeProvider theme={createMuiTheme()}>
          <Snackbar
            classes={{}}
            open={this.props.message.show}
            message={this.props.message.message}
            autoHideDuration={4000}
            onClose={() => this.props.hideMessage()}
          />
        </MuiThemeProvider>
        <LoadingIndicator/>
      </>
    )
  }
}

function mapDispatchToState(dispatch) {
  return bindActionCreators({
    hideMessage,
    toggleSidebar,
    userLogin,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    user: new UserModel(state.users.user),
    message: state.message,
    company_id: state.settings.company_id,
    app_properties: state.appProperties,
    selectedCompany: state.streamCompanies.selected,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToState)(App));
