export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_RELOAD = 'USER_RELOAD'
export const USER_LIST = 'USER_LIST'

export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const USER_SETTINGS = 'USER_SETTINGS';

export const COMPANIES_LIST = 'COMPANIES_LIST';
export const DOMAINS_LIST = 'DOMAINS_LIST';
export const RIGHTS_LIST = 'RIGHTS_LIST';

// Messages
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// Loading indicator
export const SHOW_LOADING_INDICATOR = 'SHOW_LOADING_INDICATOR';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';

// For setup of new policy.
export const NEXT_STEP = 'NEXT_STEP';
export const CHOOSE_DESIGN = 'CHOOSE_DESIGN';
export const GENERAL_SETTINGS = 'GENERAL_SETTINGS';
export const STYLE_SETTINGS = 'STYLE_SETTINGS';
export const DEFINE_PURPOSES = 'DEFINE_PURPOSES';
export const DEFINE_RECEIVERS = 'DEFINE_RECEIVERS';
export const STORE_POLICY = 'STORE_POLICY';
export const TRANSLATE_BUTTONS = 'TRANSLATE_BUTTONS';
export const TRANSLATE_PURPOSES = 'TRANSLATE_PURPOSES';
export const SET_HEADERTEXT = 'SET_HEADERTEXT';
export const SET_BODYTEXT = 'SET_BODYTEXT';
export const EDIT_POLICY = 'EDIT_POLICY';
export const CONNECTION_SETTINGS = 'CONNECTION_SETTINGS';
export const SETTINGS_HEADER = 'SETTINGS_HEADER';
export const SETTINGS_DNT = 'SETTINGS_DNT';
export const REMOVE_OPERATION = 'REMOVE_OPERATION'
export const ADD_OPERATION = 'ADD_OPERATION'
export const UPDATE_OPERATION = 'UPDATE_OPERATION'
export const PUBLISH_POLICY = 'PUBLISH_POLICY'

// For setup of new connection
export const CHANGE_SCHEMA = 'CHANGE_SCHEMA';
export const EDIT_CONNECTION = 'EDIT_CONNECTION';
export const CREATE_CONNECTION = 'CREATE_CONNECTION';
export const CREATE_COMPONENT = 'CREATE_COMPONENT';
export const CREATE_COMPONENT_PARAMETERS = 'CREATE_COMPONENT_PARAMETERS';
export const LIST_CONNECTIONS = 'LIST_CONNECTIONS'

// For setting app properties
export const SET_APP_BREADCRUMB = 'SET_APP_BREADCRUMB'
export const SET_ON_INFO_CLICK = 'SET_ON_INFO_CLICK'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR'

// ---------------------- DSM STREAM MANAGEMENT -----------------------------

// CRUD operations for STREAM
export const CREATE_STREAM = 'CREATE_STREAM'
export const DELETE_STREAM = 'DELETE_STREAM'
export const EDIT_STREAM   = 'EDIT_STREAM'
export const GET_STREAM    = 'GET_STREAM'
export const LIST_STREAMS  = 'LIST_STREAMS'
export const START_STREAM  = 'START_STREAM'
export const STOP_STREAM   = 'STOP_STREAM'
export const DATA_STREAM = 'DATA_STREAM'

export const ADD_OUTGOING_OPERATION_DATA = 'ADD_OUTGOING_OPERATION_DATA'

export const CREATE_STREAM_PARAMETER = 'CREATE_STREAM_PARAMETER'
export const DELETE_STREAM_PARAMETER = 'DELETE_STREAM_PARAMETER'
export const UPDATE_STREAM_PARAMETER = 'UPDATE_STREAM_PARAMETER'

export const CREATE_STREAM_CONNECTION = 'CREATE_STREAM_CONNECTION'
export const LIST_STREAM_CONNECTIONS = 'LIST_STREAM_CONNECTIONS'
export const UPDATE_STREAM_CONNECTION = 'UPDATE_STREAM_CONNECTION'
export const DELETE_STREAM_CONNECTION = 'DELETE_STREAM_CONNECTION'
export const GET_STREAM_CONNECTION = 'GET_STREAM_CONNECTION'
export const CREATE_STREAM_CONNECTION_SCHEMA = 'CREATE_STREAM_CONNECTION_SCHEMA'
export const UPDATE_STREAM_SLA = 'UPDATE_STREAM_SLA'

export const CREATE_STREAM_SEGMENT= 'CREATE_STREAM_SEGMENT'
export const LIST_STREAM_SEGMENTS = 'LIST_STREAM_SEGMENTS'
export const UPDATE_STREAM_SEGMENT= 'UPDATE_STREAM_SEGMENT'
export const DELETE_STREAM_SEGMENT = 'DELETE_STREAM_SEGMENT '
export const GET_STREAM_SEGMENT = 'GET_STREAM_SEGMENT'

export const DELETE_EDGE = 'DELETE_EDGE'

//ALERTS
export const CREATE_ALERT = 'CREATE_ALERT'
export const UPDATE_ALERT = 'UPDATE_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'
export const GET_ALERT    = 'GET_ALERT'
export const LIST_ALERTS  = 'LIST_ALERTS'

export const CREATE_GROUP = 'CREATE_GROUP'
export const LIST_GROUPS  = 'LIST_GROUPS'
export const DELETE_GROUP = 'DELETE_GROUP'

export const CREATE_CONDITION = 'CREATE_CONDITION'
export const UPDATE_CONDITION = 'UPDATE_CONDITION'
export const DELETE_CONDITION = 'DELETE_CONDITION'
export const LIST_CONDITIONS   = 'LIST_CONDITIONS'
export const GET_STREAM_CONNECTION_SCHEMA = 'GET_STREAM_CONNECTION_SCHEMA'
export const UPDATE_STREAM_CONNECTION_SCHEMA = 'UPDATE_STREAM_CONNECTION_SCHEMA'

// Companies
export const CREATE_STREAM_COMPANY = 'CREATE_STREAM_COMPANY'
export const LIST_STREAM_COMPANIES = 'LIST_STREAM_COMPANIES'
export const UPDATE_STREAM_COMPANY = 'UPDATE_STREAM_COMPANY'
export const DELETE_STREAM_COMPANY = 'DELETE_STREAM_COMPANY'
export const GET_STREAM_COMPANY = 'GET_STREAM_COMPANY'
export const SELECT_STREAM_COMPANY = 'SELECT_STREAM_COMPANY'

// Projects
export const CREATE_STREAM_PROJECT = 'CREATE_STREAM_PROJECT'
export const LIST_STREAM_PROJECTS = 'LIST_STREAM_PROJECTS'
export const UPDATE_STREAM_PROJECT = 'UPDATE_STREAM_PROJECT'
export const DELETE_STREAM_PROJECT = 'DELETE_STREAM_PROJECT'
export const GET_STREAM_PROJECT = 'GET_STREAM_PROJECT'
export const SELECT_STREAM_PROJECT = 'SELECT_STREAM_PROJECT'

// Users
export const CREATE_STREAM_USER = 'CREATE_STREAM_USER'
export const LIST_STREAM_USERS = 'LIST_STREAM_USERS'
export const UPDATE_STREAM_USER = 'UPDATE_STREAM_USER'
export const DELETE_STREAM_USER = 'DELETE_STREAM_USER'
export const GET_STREAM_USER = 'GET_STREAM_USER'

// Domains
export const CREATE_STREAM_DOMAINS = 'CREATE_STREAM_DOMAINS'
export const LIST_STREAM_DOMAINS = 'LIST_STREAM_DOMAINS'
export const DELETE_STREAM_DOMAINS = 'DELETE_STREAM_DOMAINS'

// Errors/messages
export const GET_STREAM_DEBUG = 'GET_STREAM_DEBUG'
export const CLEAR_STREAM_DEBUG = 'CLEAR_STREAM_DEBUG'


// Secrets
export const CREATE_SECRET = 'CREATE_SECRET'
export const UPDATE_SECRET = 'UPDATE_SECRET'
export const DELETE_SECRET = 'DELETE_SECRET'
export const GET_SECRET    = 'GET_SECRET'
export const LIST_SECRETS  = 'LIST_SECRETS'
