import User from '../models/users/user'
import { Module } from '../typings/config'

export const authorize = (mod: Module, roles?: string[]) => {
  const user = User.get()
  const moduleAllowed = user.feature_enabled(mod)
  let roleAllowed = true

  // TODO
  // We hard check for the operator here because it is the only module as of now that makes use of the new permission system
  // Next up will be governance and after QA is finished we can remove the hard checks altogheter.
  if(moduleAllowed === true && roles && mod === 'operator'){

    roleAllowed = user.groups.some((role: string) => {
      if(roles.indexOf(role) > -1){
        return true
      } else {
        let parsed_role = role.split('_')[0]
        return roles.indexOf(parsed_role) > -1
      }
    })
    return moduleAllowed && roleAllowed
  }

  if(roles && user.modules[mod]){
    const userRoles = user.modules[mod]['roles']
    roleAllowed = userRoles.some(role => roles.indexOf(role) > -1)
  }

  return moduleAllowed && roleAllowed
}
