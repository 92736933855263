import axios, { AxiosRequestConfig } from 'axios';
import {store} from '..';
import {reload} from '../actions/users';
import User from '../models/users/user'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export let rootURL = "https://dev-david.megazord.datastreams.io/module/qmon";

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" &&
           process.env.REACT_APP_ENVIRONMENT === "production") {
  rootURL = 'https://server.qmon.io/v1.0'
}

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" &&
           process.env.REACT_APP_ENVIRONMENT === "acceptance") {
  rootURL = 'https://qa.qmon.io/v1.0'
}


const createRequestConfig = ()=>{

  if(User.get().qa2py_enabled()){
    rootURL = 'https://qadev.megazord.datastreams.io/module/qmon/'
  }

  const axiosRequestConfig: AxiosRequestConfig = {
    baseURL: rootURL,
    headers: {
      accept: "application/json"
    },
    transformRequest: [function (data, headers) {
      if(User.get().authorized())
        headers['Authorization'] = "Bearer " + (User.get().qa2py_enabled() ? User.get().access_token : User.get().token_for('qa'))

      headers['Content-Type'] = 'application/json;charset=utf-8'
      if(typeof data === 'object'){
        return JSON.stringify(data)
      } else {
        return data
      }
    }],
    validateStatus: status => {
      const isValid = status >= 200 && status < 400;
      const isAuthorized = status !== 401;
      return isValid && isAuthorized
    }
  }
  return axiosRequestConfig
}




const instance = (() => {
  const ax = axios.create(createRequestConfig());
  createAuthRefreshInterceptor(ax, (failedRequest)=>{
    return new Promise((resolve, reject)=>{
      reload()((action)=>{
        store.dispatch(action)
        resolve()
      })
    })
  }, {retryInstance: axios.create(createRequestConfig())});
  return ax
})();

export default instance;
