import {HIDE_LOADING_INDICATOR, SHOW_LOADING_INDICATOR} from '.';

export const showLoading = () => {
  return ({
    type: SHOW_LOADING_INDICATOR,
    show: true
  });
};

export const hideLoading = () => {
  return ({
    type: HIDE_LOADING_INDICATOR,
    show: false
  });
};

export type LoadingAction = ReturnType<typeof showLoading | typeof hideLoading>