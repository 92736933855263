import { IWebsiteConnection } from '../models/connections/index';
import {CREATE_STREAM_CONNECTION, DELETE_STREAM_CONNECTION, GET_STREAM_CONNECTION,
  CREATE_STREAM_CONNECTION_SCHEMA, GET_STREAM_CONNECTION_SCHEMA, UPDATE_STREAM_CONNECTION_SCHEMA,
  LIST_STREAM_CONNECTIONS, UPDATE_STREAM_CONNECTION} from '../actions'

export interface IStreamsConnection {
  id: number
  updated_at: string
  created_at: string
  name: string
  description: string
  hostname: string
  port: number
  project: number
  type: string
  schema?:  any
  readable: boolean,
  writeable: boolean
}

// A placeholder value for defining new connections.
export const NEW_CONNECTION_VALUE = 0

export type initialStreamConnectionState = {
  connections: (IWebsiteConnection | IStreamsConnection)[]
}

const initialState: initialStreamConnectionState = {
  connections: [] as IStreamsConnection[]
}

const streamConnections = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STREAM_CONNECTION:
    return {
      connections: [...state.connections, action.connection]
    }
    case DELETE_STREAM_CONNECTION:
      return state
    case GET_STREAM_CONNECTION:
      return state
    case LIST_STREAM_CONNECTIONS:
      return {
        connections: action.connections
      }
    case UPDATE_STREAM_CONNECTION:
      return state
    case CREATE_STREAM_CONNECTION_SCHEMA:
      return state
    case GET_STREAM_CONNECTION_SCHEMA:
      let connection = state.connections.filter((c: any) => c.id === action.id)[0]
      connection.schema = action.schema

      return state
    case UPDATE_STREAM_CONNECTION_SCHEMA:
      connection = state.connections.filter((c: any) => c.id === action.id)[0]
      connection.schema = action.schema

      return state
    default:
      return state;
  }
}

export default streamConnections
