import {HIDE_MESSAGE, SHOW_MESSAGE} from '../actions';

export interface IMessage {
  message: string,
  show: boolean,
}

const initialState: IMessage = {
  message: "",
  show: false,
};

const message = (state: IMessage = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
    case HIDE_MESSAGE:
      return {
        ...state,
        message: action.message,
        show: action.show
      };
    default:
      return state;
  }
};

export default message;
