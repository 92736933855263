import styled from "react-emotion";
import {css} from 'glamor'

let skRotate = css.keyframes({
  '100%': {transform: 'rotate(360deg)', '@WebkitTransform': 'rotate(360deg)'}
})
let skBounce = css.keyframes({
  '0%': {transform: 'scale(0.0)', '@WebkitTransform': 'scale(0.0)'},
  '100%': {transform: 'scale(0.0)', '@WebkitTransform': 'scale(0.0)'},
  '50%': {transform: 'scale(1.0)', '@WebkitTransform': 'scale(1.0)'}
})
export const BackgroundSpinner = styled("div")({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: '10000'
});
export const Spinner = styled("div")({
  margin: '45vh auto',
  width: '40px',
  height: '40px',
  position: 'relative',
  textAlign: 'center',
  animation: `${skRotate} 2.0s infinite linear`
});
export const Dot1 = styled("div")({
  width: '60%',
  height: '60%',
  display: 'inline-block',
  position: 'absolute',
  top: '0',
  backgroundColor: 'white',
  borderRadius: '100%',
  animation: `${skBounce} 2.0s infinite ease-in-out`
});
export const Dot2 = styled("div")({
  width: '60%',
  height: '60%',
  display: 'inline-block',
  position: 'absolute',
  backgroundColor: 'white',
  borderRadius: '100%',
  animation: `${skBounce} 2.0s infinite ease-in-out`,
  top: 'auto',
  bottom: '0',
  //-webkit-animation-delay: '-1.0s'
  animationDelay: '-1.0s'
});