// Core React packages
import {createBrowserHistory} from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {USER_LOGOUT} from './actions';
import * as Sentry from '@sentry/browser';
import {showMessage} from './actions/message';

// Redux
import {ConnectedRouter, push, routerMiddleware} from 'connected-react-router'
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore, Store} from 'redux';
import thunk from 'redux-thunk';

// Material UI
import {createMuiTheme, MuiThemeProvider} from 'material-ui/styles';
import {ACCENT_PALETTE, PRIMARY_PALETTE} from './main.style';
import reducerCollection from './reducers';
// Local files containing the basics of the application
import App from './views/application/app';

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);

function redirectOnLogout({dispatch}){
  return next => action => {
    if(action.type === USER_LOGOUT){
      dispatch(push('/login'))
      window.location.reload()
    }
    return next(action)
  }
}

function handleErrors({dispatch}){
  return next => action => {
    if(action.type && action.type.indexOf('_ERROR') > -1){
      dispatch(showMessage("Something went wrong, please try again later..."));
      if(action.type.indexOf('STREAM_ERROR') > -1 && action?.payload?.response?.data?.detail){
        dispatch(showMessage(action.payload.response.data.detail));
      }
    }
    return next(action)
  }
}


/**
 * Enable error tracking for production and acceptance.
 */
if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "acceptance") {
  Sentry.init({
    dsn: "https://efa8a605e9754bea93f12e89c1c6772f@sentry.io/1516773",
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}


export const store: Store = createStore(
  reducerCollection(history),
  compose(
    applyMiddleware(middleware, thunk, redirectOnLogout, handleErrors),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);

const theme = createMuiTheme({
  palette: {
    primary: PRIMARY_PALETTE,
    secondary: ACCENT_PALETTE,
    error: ACCENT_PALETTE
  },
  spacing: {
    unit: 8
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: 10,
        marginBottom: 10
      }
    },
    MuiMenuItem: {
      root: {
        paddingLeft: '50px',
        backgroundColor: 'transparent !important',
      }
    },
    MuiDialogTitle: { // Styles to match CardHeader ones in Controller. Check if this is necessary with material-ui 4
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.5rem',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.35417em'
      }
    }
  }
});

// TODO: this seems to be required with the current version of material-UI.
// Otherwise it crashes on some rare ocassions.
// tslint:disable-next-line: no-string-literal
theme["shape"] = {}

// Render root application
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
