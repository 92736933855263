import {push} from 'connected-react-router'
import {USER_LOGIN, USER_LOGOUT, USER_RELOAD} from '.';
import {QmonActionCreators} from './qmon'
import {listStreamCompanies} from './stream-companies';
import {listStreamProjects} from './stream-projects';
import User from '../models/users/user'
import instance from '../helpers/streams-instance';

const userLoginFinal = (user: User) => ({
  type: USER_LOGIN,
  user,
});

const userReload = (user: User) => ({
  type: USER_RELOAD,
  user
})

export const reload = () =>{
  return async dispatch => {
    try {
      const result = await instance.post(`/token/refresh/`, {refresh: User.get().refresh_token})
      const usr = User.fromToken(result.data)
      usr.save()
      dispatch(userReload(usr))
    } catch(e){
      dispatch(userLogout())
    }
  }
}

// tslint:disable-next-line:variable-name
export const userLogin = (user: User, redirectPath: null | string, company: null) => {
  return async dispatch => {
    if(user.authorized()){

      user.save()

      if(company)
        user.set_company_context(company)

      dispatch(userLoginFinal(user))

      dispatch(listStreamCompanies(()=>{
        dispatch(listStreamProjects(async ()=>{

          if (user.qa_enabled() || user.qa2py_enabled()){
            dispatch(QmonActionCreators.retrieveUserProfile());
            dispatch(await QmonActionCreators.retrieveWebsites())
            dispatch(await QmonActionCreators.retrieveCompanies())
          }

          if(!redirectPath) {
            if(user.qa_enabled() && !user.privacy_and_compliance_enabled()){
              dispatch(push('/quality-assurance/dashboard'))
            } else if(user.privacy_and_compliance_enabled()) {
              dispatch(push('/governance/dashboard'))
            } else {
              dispatch(push('/streams/dashboard'))
            }
          } else {
            dispatch(push(redirectPath))
          }
        }))
      }))


    } else {
      dispatch(push('/login'))
    }
  };
};

export const userLogout = () =>{
  return async dispatch => {
      return instance.delete(`/users/token`, {data: {refresh: User.get().refresh_token}}).then(()=>{
        dispatch(logout())
        dispatch(push('/login'))
      }).catch(()=>{
        dispatch(logout())
        dispatch(push('/login'))
      })
  }
}

export const logout = () => {
  return ({
    type: USER_LOGOUT
  });
};

