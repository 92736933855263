import instance from '../helpers/streams-instance';
import {CREATE_STREAM_PROJECT,
        DELETE_STREAM_PROJECT,
        GET_STREAM_PROJECT,
        LIST_STREAM_PROJECTS,
        UPDATE_STREAM_PROJECT,
        SELECT_STREAM_PROJECT} from './index'
import {showLoading, hideLoading} from './loading'
import {IStreamsProject, IStreamsProjectBase} from '../models/projects'
import { showMessage } from './message';

/* START--------- GET *-------- */
const returnGetStreamProject = (project: IStreamsProject) => {
  return ({
      type: GET_STREAM_PROJECT,
      project
  })
}

export const getStreamProject = (id: number) =>{
  return dispatch => {
      instance.get(`/projects/${id}/`)
      .then(result => dispatch(returnGetStreamProject(result.data)))
      .catch(error => console.error(error));
  }
}
/* ------------ GET *--------END */


/* START--------- CREATE *-------- */
const returnCreateStreamProject = (project: IStreamsProject) => {
  return ({
      type: CREATE_STREAM_PROJECT,
      project
  })
}

export const createStreamProject = (payload: IStreamsProjectBase) =>{
  return async dispatch => {
      dispatch(showLoading())
      instance.post('/projects/', payload)
      .then(result => {
        dispatch(returnCreateStreamProject(result.data))
        dispatch(hideLoading())
        dispatch(showMessage('Project created successfully'))
        return result.data
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
        dispatch(showMessage('There was a problem trying to create the project' + error.toString()))
      });
  }
}
/* ------------ CREATE *--------END */


/* START--------- LIST *-------- */
const returnListStreamProjects = (projects: IStreamsProject[]) => {
  return ({
      type: LIST_STREAM_PROJECTS,
      projects
  })
}

export const listStreamProjects = (cb = ()=>{}) =>{
  return dispatch => {
      dispatch(showLoading())
      instance.get('/projects/')
      .then(result => {
        dispatch(returnListStreamProjects(result.data))
        dispatch(hideLoading())
      })
      .then(()=>cb())
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
      });
  }
}
/* ------------ LIST *--------END */


/* START--------- UPDATE *-------- */
const returnUpdateStreamProject = (project: IStreamsProject) => {
  return ({
      type: UPDATE_STREAM_PROJECT,
      project
  })
}

export const updateStreamProject = (id: number, payload: IStreamsProject) =>{
  return dispatch => {
      dispatch(showLoading())
      instance.patch(`/projects/${id}/`, payload)
      .then(result => {
        dispatch(returnUpdateStreamProject(result.data))
        dispatch(hideLoading())
        dispatch(showMessage('Project updated successfully'))
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
        dispatch(showMessage('There was a problem trying to update the project: ' + error.toString()))
      });
  }
}
/* ------------ UPDATE *--------END */


/* START--------- DELETE *-------- */
const returnDeleteStreamProject = (project: IStreamsProject) => {
  return ({
      type: DELETE_STREAM_PROJECT,
      project
  })
}

export const deleteStreamProject = (id: number) =>{
  return dispatch => {
      dispatch(showLoading())
      instance.delete(`/projects/${id}/`)
      .then(result => {
        dispatch(listStreamProjects())
        dispatch(returnDeleteStreamProject(result.data))
        dispatch(hideLoading())
        dispatch(showMessage('Project deleted successfully'))
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
        dispatch(showMessage('There was a problem trying to delete the project: ' + error.toString()))
        if( error.response.status === 412 && error?.response?.data?.e &&
            error?.response?.data?.e.indexOf("Cannot delete some instances of") > -1) {
               let errorMessageStart = 'Cannot delete because first the following items need to be deleted: ';
               let errorMessage = errorMessageStart +error?.response?.data?.items;
               dispatch(showMessage('There was a problem trying to delete the project: ' + errorMessage ))
        }
      });
  }
}
/* ------------ DELETE *--------END */

/* START--------- SELECT *-------- */
export const selectProject = (project: IStreamsProject) => {
  return dispatch => {
    dispatch(selectProjectEvent(project))
  }
}

const selectProjectEvent = (project: IStreamsProject) => {
  return {
    type: SELECT_STREAM_PROJECT,
    project
  }
}
/* ------------ SELECT *--------END */
