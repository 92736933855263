import {
  CREATE_STREAM_COMPANY,
  DELETE_STREAM_COMPANY,
  GET_STREAM_COMPANY,
  LIST_STREAM_COMPANIES,
  UPDATE_STREAM_COMPANY,
  SELECT_STREAM_COMPANY} from '../actions'
import {IStreamsCompany} from '../models/companies'
import { storeEntityIdByArray, storeEntityId } from '../helpers/storage';

type initialState = {
  companies: IStreamsCompany[]
  selected?: IStreamsCompany
}

const initialState: initialState = {
  companies: [] as IStreamsCompany[]
}

const streamCompanies = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STREAM_COMPANY:
    return {
      companies: [...state.companies, action.company]
    }
    case DELETE_STREAM_COMPANY:
      return state
    case GET_STREAM_COMPANY:
      return state
    case LIST_STREAM_COMPANIES:
      let selectedCompany: IStreamsCompany | null = null

      // Check if we have a previously selected company.
      if(sessionStorage.getItem("selected_stream_company_id") && action.companies.find(c=>c.id == sessionStorage.getItem("selected_stream_company_id"))) {
        const selectedId = sessionStorage.getItem("selected_stream_company_id") || '-22222'
        selectedCompany = storeEntityIdByArray("selected_stream_company_id", action.companies, parseInt(selectedId)) || null
      } else if(!selectedCompany) {
        // Nothing found, lets check if we can select the first company.
        selectedCompany = action.companies.length > 0 ? action.companies[0] : null
      }

      if(selectedCompany) {
        return {
          ...state,
          companies: [...action.companies],
          selected: {...storeEntityId("selected_stream_company_id", selectedCompany)}
        }
      }

      return {
        ...state,
        companies: action.companies
      }
    case UPDATE_STREAM_COMPANY:
      return state
    case SELECT_STREAM_COMPANY:
      if (action.company) {
        sessionStorage.setItem("selected_stream_company_id", action.company.id)
      }
      return {
        ...state,
        selected: {...action.company}
      }
    default:
      return state;
  }
}

export default streamCompanies
