import instance from '../helpers/streams-instance';

import {DOMAINS_LIST} from '.';

const returnDomains = (domains) => {
  return ({
    type: DOMAINS_LIST,
    domains
  })
};

export const retrieveDomains = () => {
  return dispatch => {
    instance.get(`/domains/`)
      .then(result => dispatch(returnDomains(result.data)))
      .catch(error => console.error(error));
  }
};
