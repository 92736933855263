import axios, { AxiosRequestConfig } from 'axios';
import {store} from '..';
import {reload} from '../actions/users';
import User from '../models/users/user'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export let rootURL = 'https://ctrl-backend-staging.datastreams.io'

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" && process.env.REACT_APP_ENVIRONMENT === "production") {
  rootURL = 'https://ctrl-backend.datastreams.io'
}

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined" && process.env.REACT_APP_ENVIRONMENT === "acceptance") {
  rootURL = 'https://ctrl-backend-staging.datastreams.io'
}

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: rootURL,
  headers: {
    accept: "application/json"
  },
  transformRequest: [function (data, headers) {
    if(User.get().authorized())
      headers['Authorization'] = "Bearer " + User.get().token_for('privacy_and_compliance')
    headers['Content-Type'] = 'application/json;charset=utf-8'
    return JSON.stringify(data)
  }],
  validateStatus: status => {
    const isValid = status >= 200 && status < 400;
    const isAuthorized = status !== 401;
    return isValid && isAuthorized
  }
}

const instance = (() => {
  const ax = axios.create(axiosRequestConfig);
  createAuthRefreshInterceptor(ax, (failedRequest)=>{
    return new Promise((resolve, reject)=>{
      reload()((action)=>{
        store.dispatch(action)
        resolve()
      })
    })
  }, {retryInstance: axios.create(axiosRequestConfig)});
  return ax
})();

export default instance;
