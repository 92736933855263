import {HIDE_MESSAGE, SHOW_MESSAGE} from '.';
import {IMessage} from '../reducers/message'

const showMessageFinal = (message: IMessage[]) => ({
  type: SHOW_MESSAGE,
  message,
  show: true
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
  message: '',
  show: false
});

export const showMessage = (message) => {
  return dispatch => {
    dispatch(showMessageFinal(message));
    setTimeout(() => dispatch(hideMessage()), 5000);
  };
};
