import instance from '../helpers/streams-instance';
import {showLoading, hideLoading} from './loading'
import {showMessage} from './message';

export interface ICRUDActions {
  readonly GET_ACTION_CONSTANT: string
  readonly CREATE_ACTION_CONSTANT: string
  readonly LIST_ACTION_CONSTANT: string
  readonly UPDATE_ACTION_CONSTANT: string
  readonly DELETE_ACTION_CONSTANT: string
}

export interface ICRUDPaths {
  readonly GET_PATH_CONSTANT: (id: number) => string
  readonly CREATE_PATH_CONSTANT: string
  readonly LIST_PATH_CONSTANT: string
  readonly UPDATE_PATH_CONSTANT: (id: number) => string
  readonly DELETE_PATH_CONSTANT: (id: number) => string
}

export default class StreamsCRUD<T, TBase> {
  private actions: ICRUDActions
  private paths: ICRUDPaths

  constructor(actions: ICRUDActions, paths: ICRUDPaths) {
    this.actions = actions
    this.paths = paths
  }

  /* START--------- GET *-------- */
  private readonly returnGetStreamObject = <T>(streamsObject: T) => {
    return ({
        type: this.actions.GET_ACTION_CONSTANT,
        streamsObject
    })
  }

  public readonly getStreamObject = <T>(id: number) =>{
    return dispatch => {
        dispatch(showLoading())
        instance.get(this.paths.GET_PATH_CONSTANT(id)) // `/objects/${id}/`
        .then(result => {
          dispatch(this.returnGetStreamObject<T>(result.data))
          dispatch(hideLoading())
        })
        .catch(error => {
          dispatch(hideLoading())
          dispatch(showMessage(`There was a problem loading data. Make sure your internet connection works and you are logged in`))
          console.error(error)
        })
    }
  }
  /* ------------ GET *--------END */


  /* START--------- CREATE *-------- */
  readonly returnCreateStreamObject = <T>(streamsObject: T) => {
    return ({
        type: this.actions.CREATE_ACTION_CONSTANT,
        streamsObject
    })
  }

  public readonly createStreamObject = <T, TBase>(payload: TBase, cb?: Function) =>{
    return dispatch => {
        dispatch(showLoading())
        instance.post(this.paths.CREATE_PATH_CONSTANT, payload) // '/objects/'
        .then(result => {
          if(cb){ cb() }
          dispatch(this.returnCreateStreamObject<T>(result.data))
          dispatch(hideLoading())
          dispatch(showMessage('Added succesfully'))
        })
        .catch(error => {
          const r = error.response.data
          let message = ""
          if(typeof r === 'object')
            message = `: ${Object.keys(r).map(k => r[k].map(kr => `${k} - ${kr}`)).flat().join(', ')}`
          dispatch(hideLoading())
          dispatch(showMessage(`There was a problem adding this item ${message}`))
          console.error(error.response.data)
        });
    }
  }
  /* ------------ CREATE *--------END */


  /* START--------- LIST *-------- */
  readonly returnListStreamObjects = <T>(streamsObjects: T[]) => {
    return ({
        type: this.actions.LIST_ACTION_CONSTANT,
        streamsObjects
    })
  }

  public readonly listStreamObjects = <T>() =>{
    return dispatch => {
        dispatch(showLoading())
        instance.get(this.paths.LIST_PATH_CONSTANT)
        .then(result => {
          dispatch(hideLoading())
          dispatch(this.returnListStreamObjects<T>(result.data))
        })
        .catch(error => {
          dispatch(hideLoading())
          dispatch(showMessage(`There was a problem loading data. Make sure your internet connection works and you are logged in`))
          console.error(error)
        });
    }
  }
  /* ------------ LIST *--------END */


  /* START--------- UPDATE *-------- */
  readonly returnUpdateStreamObject = <T>(streamsObject: T) => {
    return ({
        type: this.actions.UPDATE_ACTION_CONSTANT,
        streamsObject
    })
  }

  public readonly updateStreamObject = <T>(id: number, payload: T) =>{
    return dispatch => {
        dispatch(showLoading())
        instance.patch(this.paths.UPDATE_PATH_CONSTANT(id), payload) // `/objects/${id}/`
        .then(result => {
          dispatch(this.returnUpdateStreamObject<T>(result.data))
          dispatch(hideLoading())
          dispatch(showMessage('Updated succesfully'))
        })
        .catch(error => {
          dispatch(hideLoading())
          dispatch(showMessage(`There was a problem updating: ${error}`))
          console.error(error)
        });
    }
  }
  /* ------------ UPDATE *--------END */


  /* START--------- DELETE *-------- */
  returnDeleteStreamObject = <T>(streamsObject: T) => {
    return ({
        type: this.actions.DELETE_ACTION_CONSTANT,
        streamsObject
    })
  }

  public readonly deleteStreamObject = <T>(id: number) =>{
    return dispatch => {
        dispatch(showLoading())
        instance.delete(this.paths.DELETE_PATH_CONSTANT(id)) // `/objects/${id}`
        .then(result => {
          dispatch(this.listStreamObjects<T>())
          dispatch(this.returnDeleteStreamObject<T>(result.data))
          dispatch(hideLoading())
          dispatch(showMessage('Deleted succesfully'))
        })
        .catch(error => {
          dispatch(hideLoading())
          dispatch(showMessage(`There was a problem deleting: ${error}`))
          console.error(error)
        });
    }
  }
  /* ------------ DELETE *--------END */
}
