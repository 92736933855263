import instance from '../helpers/streams-instance';
import { ILabelTemplate, ILabelColumn } from '../reducers/labels';
import { showMessage } from './message';
import { showLoading, hideLoading } from './loading';


export const LABELS_LIST = 'LABELS_LIST'
export const LABELS_ADD = 'LABELS_ADD'
export const LABELS_DELETE = 'LABELS_DELETE'
export const LABELS_CELL_UPDATE = 'LABELS_CELL_UPDATE'
export const LABELS_COLUMN_ADD = 'LABELS_COLUMN_ADD'
export const LABELS_COLUMN_DELETE = 'LABELS_COLUMN_DELETE'
export const LABELS_COLUMN_UPDATE = 'LABELS_COLUMN_UPDATE'
export const LABELS_LIST_ITEM_ADD = 'LABELS_LIST_ITEM_ADD'
export const LABELS_LIST_ITEM_DELETE = 'LABELS_LIST_ITEM_DELETE'
export const LABELS_ROW_ADD = 'LABELS_ROW_ADD'


const returnLabels = (labels: ILabelTemplate[]) => {
  return {
    type: LABELS_LIST,
    labels
  } as const
}

export const fetchLabels = () => {
  return dispatch => {
    dispatch(showLoading())
    instance.get('/labels/')
      .then(result => {
        dispatch(returnLabels(result.data))
        dispatch(hideLoading())
      })
      .catch(error => {
        dispatch(hideLoading())
        dispatch(showMessage('There was a problem during the retrieval of labels.'))
        console.error(error)
      });
  }
}

export const addTemplate = (name: string, project_id: number) => async dispatch => {
  try{
    const result = await instance.post('/labels/', {name, project: project_id})
    dispatch({type:LABELS_ADD, label: result.data} as const)
    dispatch(showMessage(`Successfully added label template '${result.data.name}'`))
    return result.data
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const deleteTemplate = (id: number) => async dispatch => {
  try {
    const result = await instance.delete(`/labels/${id}/`)
    dispatch(<const>{type: LABELS_DELETE, template_id: id})
    dispatch(showMessage("Successfully removed label template!"))
    return result.data
  }catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const addLabelColumn = (column: ILabelColumn) => {
  return async dispatch => {
    try {
      const result = await  instance.post('/label-columns/', column)
      dispatch({
        type: LABELS_COLUMN_ADD,
        column: result.data,
        template_id: column.template_id,
      } as const)
      dispatch(showMessage(`Successfully added column '${column.name}'`))
      return result.data
    } catch(error) {
      dispatch(showMessage(error.toString()))
    }
  }
}

export const updateLabelColumn = (column_id: number, payload: object) => async dispatch => {
  try {
    const result = await instance.patch(`/label-columns/${column_id}/`, payload)
    dispatch({
      type: LABELS_COLUMN_UPDATE,
      column: result.data,
    } as const)
    dispatch(showMessage(`Successfully updated column '${result.data.name}'`))
    return result.data
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const deleteLabelColumn = (column_id: number) => async dispatch => {
  try{
    await instance.delete(`/label-columns/${column_id}/`)
    dispatch({
      type: LABELS_COLUMN_DELETE,
      column_id,
    } as const)
    dispatch(showMessage('Successfully deleted the column'))
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const addListItem = (item: object) => async dispatch => {
  try {
    const result = await instance.post(`/label-column-list-items/`, item)
    dispatch({
      type: LABELS_LIST_ITEM_ADD,
      item: result.data,
    } as const)
    dispatch(showMessage(`Successfully added list item '${result.data.value}'`))
    return result.data
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const deleteListItem = (id: number) => async dispatch => {
  try {
    await instance.delete(`/label-column-list-items/${id}/`)
    dispatch({
      type: LABELS_LIST_ITEM_DELETE,
      item_id: id,
    } as const)
    dispatch(showMessage('Successfully deleted the list item'))
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const addLabelRow = (template_id: number) => async dispatch => {
  try {
    const result = await instance.put(`/labels/${template_id}/row/`)
    dispatch({type: LABELS_ROW_ADD, label: result.data} as const)
    dispatch(showMessage('Successfully added a row'))
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const deleteLabelRow = (template_id: number, row_id: number) => async dispatch => {
  try {
    await instance.delete(`/labels/${template_id}/row/${row_id}/`)
    dispatch(fetchLabels())
    dispatch(showMessage('Successfully deleted a row'))
  } catch(error) {
    dispatch(showMessage(error.toString()))
  }
}

export const updateLabelCell = (cell_id: number, payload: object) => {
  return async dispatch => {
    try {
      const result = await instance.patch(`/label-cells/${cell_id}/`, payload)
      dispatch({type: LABELS_CELL_UPDATE, cell: result.data} as const)
      dispatch(showMessage('Successfully changed the cell'))
      return result.data
    } catch(error) {
      dispatch(showMessage(error.toString()))
    }
  }
}
