import {USER_LOGIN, USER_RELOAD, USER_LOGOUT, USER_LIST} from '../actions';
import {IUser} from '../models/users/user'
import User from '../models/users/user'

interface IState {
  user: IUser,
  users: IUser[]
}

const intialState: IState = {
  user: {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    features: [],
    modules: {},
    roles: [],
    groups: [],
    companies: []
  },
  users: []
}

const users = (state = intialState, action) => {
  switch (action.type) {
    case USER_LIST:
      return { ...state, users: action.users}
    case USER_LOGOUT:
      User.logout()
      return state
    case USER_LOGIN:
      return {...state, user: action.user}
    case USER_RELOAD:
      action.user.save()
      return {...state, user: action.user}
    default:
      return state;
  }
};

export default users;
