import {UsersActionsConstants} from "../actions/stream-users";
import { IStreamsUser } from "../models/users";

type initialState = {
  users: IStreamsUser[]
}

const initialState: initialState = {
  users: [] as IStreamsUser[]
}

const usersActionsConstants = new UsersActionsConstants()

const streamUsers = (state = initialState, action) => {
  switch (action.type) {
    case usersActionsConstants.CREATE_ACTION_CONSTANT:
    return {
      users: [...state.users, action.streamsObject]
    }
    case usersActionsConstants.DELETE_ACTION_CONSTANT:
      return state
    case usersActionsConstants.GET_ACTION_CONSTANT:
      return state
    case usersActionsConstants.LIST_ACTION_CONSTANT:
      return {
        users: action.streamsObjects
      }
    case usersActionsConstants.UPDATE_ACTION_CONSTANT:
      return state
    default:
      return state;
  }
}

export default streamUsers
