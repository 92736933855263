interface IdObj {
    id: number
}

/***
 * This is an helper method for storing entity id's to sessions storage.
 * @param storageKey The name of the key to store to.
 * @param obj The object which should be saved.
 */
export const storeEntityId = <T extends IdObj>(storageKey: string, obj: T): T => {
    sessionStorage.setItem(storageKey, `${obj.id}`)
    return obj
}

/***
 * This helper method stores an object id to storage based on an array of objects
 * and an id to match to.
 * @param storageKey The name of the key to store to.
 * @param objArray An array of objects which could contain the object that should be saved.
 * @param lookupId The id to match on.
 */
export const storeEntityIdByArray = <T extends IdObj>(
    storageKey: string,
    objArray: T[],
    lookupId: number | string
): T | null => {
    const foundEntity = objArray.find(x => x.id === lookupId)
    if (foundEntity) {
        return storeEntityId(storageKey, foundEntity)
    }

    return null
}
