import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import {QmonActionCreators} from "../actions/qmon";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {ListItemText} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Button from 'material-ui/Button';
import InputIcon from 'material-ui-icons/Input'
import AccountCircle from 'material-ui-icons/AccountCircle';
import Help from 'material-ui-icons/Help';
import KeyboardArrowDown from 'material-ui-icons/KeyboardArrowDown';
import ContextSwitcher from '../views/qmon/components/context-switcher';
import ContextSwitcher2 from './context-switcher';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'

import {changeCompany, retrieveUserSettings} from '../actions/settings';
import {selectCompany} from '../actions/stream-companies';
import {selectProject} from '../actions/stream-projects';
import {userLogout, userLogin} from '../actions/users';
import User from '../models/users/user'
import { store } from '..';

import styled from "react-emotion";
import Authorize from './authorize'
import {DARK_TEXT_COLOR, HEADER_HEIGHT} from '../main.style';


const HeaderBar = styled("div")({
  display: 'inline-block',
  backgroundColor: 'white',
  color: DARK_TEXT_COLOR,
  height: HEADER_HEIGHT,
  width: '100%',
  marginBottom: '-2px',
});
const HeaderTitle = styled("h4")({
  float: 'left',
  fontSize: '1.3em',
  fontWeight: '300',
  marginTop: '8px',
  marginLeft: '15px',
});
const HeaderRightMenu = styled("span")({
  float: 'right'
});

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: '30px !important',
  "& h3:hover " : {
      color: '#000000',
  },
  "& h3 " : {
      fontFamily: 'Source Sans Pro, sans-serif',
      color: 'gray',
  },

});


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      selectedCompanyName: "",
      openUserMenu: false
    };

    this.userLogout = this.userLogout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.handleHistoryPush = this.handleHistoryPush.bind(this);
  }

  isModule(moduleName) {
    if(!this.props.pathname || typeof this.props.pathname !== 'string') {
      return false;
    }

    const path = this.props.pathname.split('/')
    if(path.length < 2) {
      // We should have at least two items: ["", "modulename", ..]
      return false;
    }

    return path[1].toLowerCase() === moduleName.toLowerCase()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.qmonSelectedCompany && !nextProps.qmonSelectedWebsite) {
      // select first website available for the company, if any
      let websitesForSelectedCompany = [];

      websitesForSelectedCompany = this.props.websites.filter(
        (website) => (website.companyId === nextProps.qmonSelectedCompany.id)
      )

      this.props.selectWebsite((websitesForSelectedCompany.length > 0) ? websitesForSelectedCompany[0] : null);
    }
  }


  userDisplayName(){
    if(this.props.user.first_name){
      return this.props.user.first_name + " " + this.props.user.last_name
    } else {
      return this.props.user.email
    }
  }

  render() {
    let allowUsersView = 'none';
    return (
      <HeaderBar>
        <HeaderTitle>
          <FontAwesomeIcon
            icon={faBars}
            onClick={this.props.toggleSidebar}
            style={{marginRight: '20px', cursor: 'pointer'}} />
        </HeaderTitle>

        <HeaderRightMenu>
          <UserMenu
            buttonName={this.userDisplayName()}
            open={this.state.openUserMenu}
          >
            <StyledMenuItem onClick={() => {
              this.handleHistoryPush('/user')
            }}>
              <ListItemIcon>
                <AccountCircle/>
              </ListItemIcon>
              <ListItemText  primary={this.userDisplayName()}/>
            </StyledMenuItem>

            <StyledMenuItem onClick={() => {
              this.handleHistoryPush('/documentation')
            }}>
              <ListItemIcon>
                <Help/>
              </ListItemIcon>
              <ListItemText primary="Documentation"/>
            </StyledMenuItem>
            <Divider/>
            <StyledMenuItem onClick={this.userLogout}>
              <ListItemIcon>
                <InputIcon/>
              </ListItemIcon>
              <ListItemText primary="Logout"/>
            </StyledMenuItem>
          </UserMenu>
        </HeaderRightMenu>

      {this.isModule('quality-assurance') ?
        <Authorize module="qa">
          <HeaderRightMenu>
            <UserMenu
              value={this.props.settings.company_id}
              buttonName={
                this.props.qmonSelectedCompany
                ? this.props.qmonSelectedCompany.name + ' / ' +
                  (this.props.qmonSelectedWebsite
                  ? this.props.qmonSelectedWebsite.name
                  : 'No website')
                : "Q/A context"}
              open={this.state.openUserMenu}
            >

              <ListItem style={{
                padding: '8px 30px',
              }}>
                <ContextSwitcher
                  closeCallback={() => this.setState({open: false})}
                />
              </ListItem>
            </UserMenu>
          </HeaderRightMenu>
        </Authorize>
      : null}

      {this.isModule('streams') || this.isModule('labels-and-segments') || this.isModule('governance') ?
        <Authorize module={["privacy_and_compliance", "operator"]}>
          <HeaderRightMenu>
            <UserMenu
              value={this.props.settings.company_id}
              buttonName={
                this.props.selectedStreamsCompany
                ? this.props.selectedStreamsCompany.name + ' / ' +
                  (this.props.selectedStreamsProject
                  ? this.props.selectedStreamsProject.name
                  : 'No project')
                : "context"}
              open={this.state.openUserMenu}
            >
              <ListItem style={{padding: '8px 30px'}}>
                <ContextSwitcher2
                  companies={this.props.streamsCompanies}
                  projects={this.props.streamsProjects}
                  selectedCompany={this.props.selectedStreamsCompany}
                  selectedProject={this.props.selectedStreamsProject}
                  onCloseCallback={() => this.setState({open: false})}
                  onPrimarySelection={company => this.props.selectStreamsCompany(company)}
                  onSecondarySelection={project => this.props.selectStreamsProject(project)} />
              </ListItem>
            </UserMenu>
          </HeaderRightMenu>
        </Authorize>
        : null}
      </HeaderBar>
    )
  }

  userLogout() {
    this.props.userLogout();
  }

  handleChange(event, index, value) {
    this.props.changeCompany(event.target.value.name, event.target.value.sfId);
  }

  companyChange(sfId, name) {
    this.setState({open: false});
    this.props.changeCompany(name, sfId);
  }

  handleHistoryPush(path) {
    this.setState({openUserMenu: false});
    this.props.history.push(path)
  }
}


// We can use inline-style
const MenuStyle = {
  fontFamily: 'Source Sans Pro, sans-serif' ,
};

class UserMenu extends Component {

  constructor(props) {
    super(props);

    this.state = { };

    this.handleRequestClose = this.handleRequestClose.bind(this);
  }


  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };


  handleRequestClose = (event) => {
    // Check that the user clicked out of the menu items - necessary for the context switcher
    if (event.target instanceof HTMLElement && event.target.getAttribute('aria-hidden'))
    {
      this.setState({open: false});
    }
  };



  UNSAFE_componentWillReceiveProps() {
    this.setState({open: this.props.open})
  }

  render() {
    return (
      <div>
        <Button
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <div className="buttonText" style={MenuStyle}>{this.props.buttonName}</div>
          <KeyboardArrowDown/>
        </Button>

        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClick={this.handleRequestClose}
          style={{fontFamily: 'Source Sans Pro, sans-serif', color: 'red'}}
        >
          {/* THIS IS FOR A BUG https://github.com/mui-org/material-ui/issues/5186 */}
          <MenuItem key="placeholder" style={{display: "none"}}/>
            {this.props.children}
        </Menu>
      </div>
    )
  }
}

function mapDispatchToState(dispatch) {
  return bindActionCreators({
    userLogout,
    changeCompany,
    retrieveUserSettings,
    selectWebsite: QmonActionCreators.selectWebsite,
    selectStreamsCompany: (company)=>{
      store.dispatch(userLogin(User.get(), null, company))
      return selectCompany(company)
    },
    selectStreamsProject: (project) => {
      store.dispatch(userLogin(User.get(), null, {id: project.company}))
      return selectProject(project)
    },
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.users.user,
    settings: state.settings,
    companies: state.companies.companies,
    pathname: state.router.location.pathname,
    websites: state.qmon.websites,
    qmonSelectedCompany: state.qmon.selected.company,
    qmonSelectedWebsite: state.qmon.selected.website,
    streamsCompanies: state.streamCompanies.companies,
    selectedStreamsCompany: state.streamCompanies.selected,
    streamsProjects: state.streamProjects.projects,
    selectedStreamsProject: state.streamProjects.selected
  }
}

export default connect(mapStateToProps, mapDispatchToState)(withRouter(Header));
