import { EMPTY_SUMMARIES, IAdobeVariable, IAlert, IContentValidation, IElement, IEvent, ISummaries, IUser, IValidationOperator, IWebsite, QmonActions } from '../actions/qmon';
import {QamonGetWebsitesForSelectedCompany} from '../selectors'

const initialState = {
  alerts: [] as IAlert[],
  alertSettings: null,
  companies: [],
  content: new Array<IContentValidation>(),
  domains: [],
  events: new Array<IEvent>() ,
  elements: new Array<IElement>(),
  invitations: new Array<IUser>(),
  selected: {
    company: null,
    website: null
  },
  summaries: EMPTY_SUMMARIES as ISummaries,
  userProfile: null,
  users: new Array<IUser>(),
  websites: [],
  diagnostics: [],
  adobevariables: [] as IAdobeVariable[],
  validationoperators: [] as IValidationOperator[]
};

const qmonState = (state = initialState, action) => {
  switch (action.type) {
    case QmonActions.QMON_DIAGNOSTICS:
      return {
        ...state,
        diagnostics: action.diagnostics
      }
    case QmonActions.QMON_ALERTS_LIST:
      return {
        ...state,
        alerts: action.alerts,
      }
    case QmonActions.QMON_COMPANIES_LIST:
      const company = action.companies.filter((c) => c.id === sessionStorage.getItem("selected_company_id"))[0] || action.companies[0];

      return {
        ...state,
        selected: {...state.selected, company},
        companies: action.companies
      };
    case QmonActions.QMON_DOMAINS_LIST:
      return {
        ...state,
        domains: action.domains
      };
    case QmonActions.QMON_WEBSITES_LIST:
      const websitesForCompany = QamonGetWebsitesForSelectedCompany()({...state, websites: action.websites})
      const website = websitesForCompany.filter((ws) => ws.id === sessionStorage.getItem("selected_website_id"))[0] || websitesForCompany[0] || null

      return {
        ...state,
        selected: {...state.selected, website},
        websites: action.websites
      };
    case QmonActions.QMON_EVENT_ALERT_SETTINGS_GET:
    case QmonActions.QMON_ELEMENT_ALERT_SETTINGS_GET:
    case QmonActions.QMON_VALIDATION_ALERT_SETTINGS_GET:
      return {
        ...state,
        alertSettings: action.alertSettings
      };
    case QmonActions.QMON_EVENTS_LIST:
      return {
        ...state,
        events: action.events
      };
    case QmonActions.QMON_EVENT_CREATE:
      let newStateEvents = new Array<IEvent>()
      newStateEvents = [...state.events]

      if (action.event.id) {
        newStateEvents = state.events.map((event) => (event.id === action.event.id) ? action.event : event)
      } else {
        newStateEvents = [...state.events, ...action.event]
      }

      return {
        ...state,
        events: [...newStateEvents]
      };
    case QmonActions.QMON_EVENT_DELETE:
      return {
        ...state,
        events: [...state.events.filter((event: IEvent) => (event.id !== action.eventId))]
      };
    case QmonActions.QMON_EVENT_SUBSCRIBE:
      return {
        ...state,
        // events: [...state.events.map(event => {
        //   if (event.id === action.subscription.eventId) {
        //     event.subscription.subscribed = true;
        //   }
        //   })]
      };
    case QmonActions.QMON_EVENT_UNSUBSCRIBE:
      return {
        ...state,
        // events: [...state.events.map(event => {
        //   if (event.subscription.subscriptionId === action.subscriptionId) {
        //     event.subscription.subscribed = false;
        //   }
        // })
        // ]
      };
    case QmonActions.QMON_ELEMENT_SUBSCRIBE:
      return {
        ...state,
        // FIXME Optimize actions that update state so that minimum changes to the events,
        // elements etc are done. As these data elements are tree structures now it is more
        // complex to update state, so the data just gets reloaded from the server
        // elements: [...state.elements.map(element => {
        //   if (element.id === action.subscription.elementId) {
        //     element.subscription.subscribed = true;
        //   }
        //   })]
      };
    case QmonActions.QMON_ELEMENT_UNSUBSCRIBE:
      return {
        ...state,
        // elements: [...state.elements.map(element => {
        //   if (element.subscription.subscriptionId === action.subscriptionId) {
        //     element.subscription.subscribed = false;
        //   }
        // })
        // ]
      };
    case QmonActions.QMON_ELEMENTS_LIST:
      const lookup: { [id: string]: any } = {}
      const rootNodes: IElement[] = []

      action.elements.map((element: IElement) => {
        const elementId = element.id
        const parentId = element.elementParentId

        if (!lookup[elementId]) {
          lookup[elementId] = {data: null, children: []}
        }

        lookup[elementId].data = element

        const node = lookup[elementId]

        if (parentId.length === 0) {
          rootNodes.push({ ...node.data, children: node.children}) // push node into final tree
        } else { // it's a child node
          if (!lookup[parentId]) { // no parent found in lookup table
            lookup[parentId] = {data: null, children: []}
          }

          lookup[parentId].children.push({ ...node.data, children: node.children})
        }
      });

      return {
        ...state,
        elements: rootNodes
      };
    case QmonActions.QMON_ELEMENT_CREATE:
      return {
        ...state,
        elements: [...state.elements, ...action.element]
      };
    case QmonActions.QMON_ELEMENT_UPDATE:
      return {
        ...state,
        elements: [...state.elements.map((element) => (element.id === action.element.id) ? action.element : element)]
      };
    case QmonActions.QMON_ELEMENT_DELETE:
      return {
        ...state,
        elements: [...state.elements.filter((element: IElement) => (element.id !== action.elementId))]
      };
    case QmonActions.QMON_CONTENT_LIST:
      return {
        ...state,
        content: action.content
      };
    case QmonActions.QMON_CONTENT_CREATE:
      return {
        ...state
      };
    case QmonActions.QMON_CONTENT_UPDATE:
      return {
        ...state
      };
    case QmonActions.QMON_CONTENT_DELETE:
      return {
        ...state,
        content: [...state.content.filter((validation: IContentValidation) => (validation.id !== action.validationId))]
      };
    case QmonActions.QMON_SELECT_COMPANY:
      if (action.company) {
        sessionStorage.setItem("selected_company_id", action.company.id);
      }

      return {
        ...state,
        selected: {...state.selected, company: action.company}
      };
    case QmonActions.QMON_SELECT_WEBSITE:
      if (action.website) {
        sessionStorage.setItem("selected_website_id", action.website.id);
      }

      return {
        ...state,
        selected: {...state.selected, website: action.website}
      };
    case QmonActions.QMON_SELECT_WEBSITE_BY_ID:
      let selectedWebsite = state.selected.website
      const matchedWebsite = state.websites.filter((ws: IWebsite) => ws.id === action.websiteId)

      if (matchedWebsite.length > 0) { // select the first website found if any
        selectedWebsite = matchedWebsite[0]
      }

      return {
        ...state,
        selected: {...state.selected, website: selectedWebsite}
      };

    case QmonActions.QMON_SUMMARIES_GET:
      return {
        ...state,
        summaries: action.summaries
      };
    case QmonActions.QMON_USERS_LIST:
      return {
        ...state,
        users: action.users
      };
    case QmonActions.QMON_USER_PROFILE_GET:
      return {
        ...state,
        userProfile: action.users
      };
    case QmonActions.QMON_USER_PROFILE_UPDATE:
      return {
        ...state,
        userProfile: action.users
      };
    case QmonActions.QMON_INVITATIONS_LIST:
      return {
        ...state,
        invitations: action.invitations
      };
    case QmonActions.QMON_ADOBE_VARIABLES_GET:
      return {
        ...state,
        adobevariables: action.variables
      };
    case QmonActions.QMON_VALIDATION_OPERATORS_GET:
      return {
        ...state,
        validationoperators: action.operators
      };
    default:
      return state;
  }
};

export default qmonState;