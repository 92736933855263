import * as React from 'react';
import {connect} from 'react-redux'

import {ILoading} from '../reducers/loading'
import {BackgroundSpinner, Dot1, Dot2, Spinner} from './loading-indicator.style'



class LoadingIndicator extends React.Component<{
  loading: ILoading
}> {
  public render() {
    return (
      <span>
        {this.props.loading.show ? (
          <BackgroundSpinner>
            <Spinner>
              <Dot1/>
              <Dot2/>
            </Spinner>
          </BackgroundSpinner>
        ) : null}
      </span>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading
  };
}

export default connect(mapStateToProps)(LoadingIndicator);