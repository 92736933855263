import {
  CHOOSE_DESIGN, CONNECTION_SETTINGS, DEFINE_PURPOSES, DEFINE_RECEIVERS, EDIT_POLICY, GENERAL_SETTINGS, NEXT_STEP,
  SET_BODYTEXT, SET_HEADERTEXT, STORE_POLICY, STYLE_SETTINGS, TRANSLATE_PURPOSES, TRANSLATE_BUTTONS, SETTINGS_DNT,
  ADD_OPERATION, REMOVE_OPERATION, UPDATE_OPERATION, SETTINGS_HEADER, PUBLISH_POLICY
} from '../actions';

const initialState = {
  stepIndex: 0,
  schema: [],
  operations: [],
  source: {},
  general: {
    design: "1",
    name: null,
    domains: [],
    languages: [],
    purposes: [],
    receivers: [],
    barText: null,
    attributes: []
  },
  connections: {
    components: [],
    links: [],
    linksBefore: [],
  },
  style: {
    dpoImage: "",
    primaryColor: null,
    secondaryColor: null,
    textColor: null,
    barColor: null,
    borderRadius: 0,
    customCss: "",
    customJs: "",
    styleConfiguration : {
      modalBackgroundGradient: false,
      bottomBarAcceptAll: false,
      showsettingslink: false,
      settingsBackgroundLines: false,
      anonymousPurpose: false,
      rtbfForm: false,
      dntHeader: false,
      openSettingsButton : false,
      bottomBar : false
    }
  },
  translations: {}
};

const policy = (state = initialState, action) => {
  switch (action.type) {
    case NEXT_STEP:
      return {
        ...state,
        stepIndex: action.stepIndex
      };
    case CHOOSE_DESIGN:
      return {
        ...state,
        general: {
          ...state.general,
          design: action.design
        }
      };
    case GENERAL_SETTINGS:
      return {
        ...state,
        general: {
          ...state.general,
          name: action.name,
          domains: action.domains,
          languages: action.languages
        },
      };
    case CONNECTION_SETTINGS:
      return {
        ...state,
        connections: {
          ...state.connections,
          components: action.components,
          links: action.links,
          linksBefore: action.linksBefore
        },
      };
    case STYLE_SETTINGS:
      return {
        ...state,
        style: {
          ...state.style,
          dpoImage: action.dpoImage,
          primaryColor: action.primaryColor,
          secondaryColor: action.secondaryColor,
          textColor: action.textColor,
          barColor: action.barColor,
          borderRadius: action.borderRadius,
          customCss: action.customCss,
          customJs: action.customJs,
          styleConfiguration : {
            modalBackgroundGradient: action.modalBackgroundGradient,
            bottomBarAcceptAll: action.bottomBarAcceptAll,
            showsettingslink: action.showsettingslink,
            settingsBackgroundLines: action.settingsBackgroundLines,
            anonymousPurpose: action.anonymousPurpose,
            rtbfForm: action.rtbfForm,
            dntHeader: action.dntHeader,
            openSettingsButton : action.openSettingsButton,
            bottomBar : action.bottomBar
          },
        }
      };
      case DEFINE_PURPOSES:
      return {
        ...state,
        general: {
          ...state.general,
          purposes: action.purposes
        }
      };
    case DEFINE_RECEIVERS:
      return {
        ...state,
        general: {
          ...state.general,
          receivers: action.receivers
        }
      };
    case STORE_POLICY:
    case PUBLISH_POLICY:
      // Add the languages as key value (objects). The key is the language shortcode
      let localState = {...state}
      let languages = action.languages.map(language => {
        localState.translations[language.name] = {
          id: language.policy_translation ? language.policy_translation.id : null,
          contact: {
            headerText: language.policy_translation ? language.policy_translation.contact_header : 'Header',
            bodyText: language.policy_translation ? language.policy_translation.contact_body : null
          },
          policy: {
            headerText: language.policy_translation ? language.policy_translation.policy_header : null,
            bodyText: language.policy_translation ? language.policy_translation.policy_body : null
          },
          rights: {
            headerText: language.policy_translation ? language.policy_translation.rights_header : null,
            bodyText: language.policy_translation ? language.policy_translation.rights_body : null
          },
          settings: {
            headerText: language.policy_translation ? language.policy_translation.settings_header : null,
            purposes: language.purposeTranslations ? language.purpose_translations : []
          },
          receivers: {
            headerText: language.policy_translation ? language.policy_translation.receivers_header : null
          },
          buttons : {
              barButton:          language.policy_translation ? language.policy_translation.buttons.barButton : '',
              barLink:            language.policy_translation ? language.policy_translation.buttons.barLink : '',
              footerClose:        language.policy_translation ? language.policy_translation.buttons.footerClose : '',
              footerContact:      language.policy_translation ? language.policy_translation.buttons.footerContact : '',
              footerReadMore:     language.policy_translation ? language.policy_translation.buttons.footerReadMore : '',
              footerRights:       language.policy_translation ? language.policy_translation.buttons.footerRights : '',
              headerGoBack:       language.policy_translation ? language.policy_translation.buttons.headerGoBack : '',
              openSettings:       language.policy_translation ? language.policy_translation.buttons.openSettings : '',
              settingsDataRights: language.policy_translation ? language.policy_translation.buttons.settingsDataRights : '',
              settingsReceivers:  language.policy_translation ? language.policy_translation.buttons.settingsReceivers : '',
              settingsRtbfForm:   language.policy_translation ? language.policy_translation.buttons.settingsRtbfForm : ''
          },
          settingsHeader: language.policy_translation ? language.policy_translation.settings_header : 'none',
          settingsDnt : language.policy_translation ? language.policy_translation.settings_dnt : 'none',
          settings: {
            purposes: action.purposes.map(purpose => ({
              id: purpose.id,
              name: purpose.name,
              title: "",
              description: ""
            }))
          }
        }
      })

      return {
        ...localState,
        languages,
        general: {
          ...state.general,
          policyId: action.policyId,
          attributes: action.attributes,
          fromOperationId: action.fromOperationId,
          barText: action.barText,
          languages: action.languages
        },
        operations: action.operations,
        schema: action.schema,
        source: action.source,
        editingSettings:action.editingSettings,
        publishes: action.publishes,
        id: action.id,
      };

    case TRANSLATE_BUTTONS:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.language]: {
            ...state.translations[action.language],
            buttons: action.buttons
          }
        }
      };

    case TRANSLATE_PURPOSES:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.language]: {
            ...state.translations[action.language],
            settings: {
              ...state.translations[action.language].settings,
              purposes: action.purposes
            }
          }
        }
      };

  case SETTINGS_HEADER:
    return {
      ...state,
      translations: {
        ...state.translations,
        [action.language]: {
          ...state.translations[action.language],
            settingsHeader: action.bodyText
          }
        }
      }

  case SETTINGS_DNT:
    return {
      ...state,
      translations: {
        ...state.translations,
        [action.language]: {
          ...state.translations[action.language],
            settingsDnt: action.bodyText
          }
        }
      }

    case SET_HEADERTEXT:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.language]: {
            ...state.translations[action.language],
            [action.selector]: {
              ...state.translations[action.language][action.selector],
              headerText: action.headerText
            }
          }
        }
      };
    case SET_BODYTEXT:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.language]: {
            ...state.translations[action.language],
            [action.selector]: {
              ...state.translations[action.language][action.selector],
              bodyText: action.bodyText
            }
          }
        }
      };
    case EDIT_POLICY:
      return {
        ...action.policy
      };
    case ADD_OPERATION:
      return {
        ...state,
        operations: [...state.operations, action.operation]
      }
    case UPDATE_OPERATION:
      return {
        ...state,
        operations: state.operations.map(op => op.id == action.operation.id ? action.operation : op)
      }
    case REMOVE_OPERATION:
      return {
        ...state,
        operations: state.operations.filter(op => op.id !== action.operation.id ? op : null).filter(Boolean)
      }
    default:
      return state;
  }
};

export default policy;
