import {CREATE_ALERT, UPDATE_ALERT, DELETE_ALERT, GET_ALERT, LIST_ALERTS} from '../actions/index'

export interface IAlert {
  id: number,
  name: string,
  description: string
  conditionGroups: ConditionGroup[]
}

export interface ConditionGroup {
  conditions: Condition[]
  operator: string
  alert?: any
}

export interface Condition{
  group?: any
  var: string,
  op: string,
  val: string
}

const initialState = {
  alerts: [] as IAlert[],
  activeAlert: {} as IAlert
}

const alerts = (state = initialState, payload) => {
  switch (payload.type) {
    case CREATE_ALERT:
      return state
    case UPDATE_ALERT:
      return state
    case DELETE_ALERT:
      return state
    case GET_ALERT:
      return {
        alerts: state.alerts.map((alert)=>{
          if(alert.id === payload.alert.id){
            return payload.alert
          }
          return alert
        }),
        activeAlert: state.activeAlert
      }
    case LIST_ALERTS:
      return {
        ...state,
        alerts: payload.alerts
      }
    default:
      return state
  }
}

export default alerts
