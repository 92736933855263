import { IStreamsEnvVar } from '../models/environment-variable'
import {CREATE_SECRET,
  DELETE_SECRET,
  GET_SECRET,
  LIST_SECRETS,
  UPDATE_SECRET} from './index'

import streamCRUD, {ICRUDActions, ICRUDPaths} from './stream-crud'

export class SecretActionsConstants implements ICRUDActions {
readonly GET_ACTION_CONSTANT = GET_SECRET
readonly CREATE_ACTION_CONSTANT = CREATE_SECRET
readonly LIST_ACTION_CONSTANT = LIST_SECRETS
readonly UPDATE_ACTION_CONSTANT = UPDATE_SECRET
readonly DELETE_ACTION_CONSTANT = DELETE_SECRET
}

const PATH_NAME = 'variables'

export class SecretPathConstants implements ICRUDPaths {
readonly GET_PATH_CONSTANT = (id: number) => `/${PATH_NAME}/${id}/`
readonly CREATE_PATH_CONSTANT = `/${PATH_NAME}/`
readonly LIST_PATH_CONSTANT = `/${PATH_NAME}/`
readonly UPDATE_PATH_CONSTANT = (id: number) => `/${PATH_NAME}/${id}/`
readonly DELETE_PATH_CONSTANT = (id: number) => `/${PATH_NAME}/${id}/`
}

const secretsCrud = new streamCRUD<IStreamsEnvVar, IStreamsEnvVar>(new SecretActionsConstants(), new SecretPathConstants())

export default secretsCrud
