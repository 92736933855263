import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {QmonActionCreators} from "../../../actions/qmon";
import Select from '@material-ui/core/Select';
import {FormControl} from 'material-ui/Form';
import Input, {InputLabel} from 'material-ui/Input';
import Authorize from '../../../components/authorize'
import MenuItemCheckbox from '../../../components/menu-item-checkbox'
import { Btn } from '../../../components/button';

class ContextSwitcher extends Component {
  constructor(props) {
    super(props);

    if (!props.selected.company) { // prevent the component from resetting the selected company and websites if they are already set
      props.retrieveCompanies();
    }

    if (props.websites.length === 0) { // retrieve the website list if it's not already populated
      props.retrieveWebsites();
    }

    this.state = {
      selectedCompany: props.selected.company,
      selectedWebsite: props.selected.website
    };

    this.onCompanyChange = this.onCompanyChange.bind(this)
    this.onWebsiteChange = this.onWebsiteChange.bind(this)
  }

  render() {
    let websitesForSelectedCompany = [];

    if (this.state.selectedCompany) {
      websitesForSelectedCompany = this.props.websites.filter(
        (website) => (website.companyId === this.state.selectedCompany.id)
      )
    }

    return (
      <div>
        {this.props.companies ? (
          <Authorize module="qa" roles={['admin']}>
            <FormControl fullWidth>

              <InputLabel htmlFor="connection-type">Company</InputLabel>
              <Select
                input={<Input id="connection-type"/>}
                value={(this.state.selectedCompany) ? this.state.selectedCompany : ""}
                onChange={this.onCompanyChange}
              >
                {this.props.companies.map(company => (
                  <MenuItemCheckbox
                    key={company.id}
                    value={company}
                  >
                    {company.name}
                  </MenuItemCheckbox>
                ))}
              </Select>
            </FormControl>
          </Authorize>
        ) : null}
        <FormControl fullWidth disabled={!this.state.selectedCompany || websitesForSelectedCompany.length === 0}>
          <InputLabel htmlFor="connection-type">Website</InputLabel>
          <Select
            input={<Input id="connection-type"/>}
            value={this.state.selectedWebsite || ""}
            onChange={this.onWebsiteChange}
          >
            {
              websitesForSelectedCompany.map(website => (
              <MenuItemCheckbox
                key={website.id}
                value={website}
              >
                {website.name}
              </MenuItemCheckbox>
            ))
            }
          </Select>
          <span style={{height: '16px'}} />
          <Btn
              aria-haspopup="true"
              style={{'width': '100%'}}
              onClick={
                () => {
                  this.setState({open: false})
                  this.props.selectCompany(this.state.selectedCompany)
                  this.props.selectWebsite(this.state.selectedWebsite);
                  // refresh context-dependent data
                  this.props.retrieveAlerts()
                  this.props.retrieveDomains()
                  this.props.retrieveEvents()
                  this.props.retrieveElements()
                  this.props.retrieveContent()

                  this.props.closeCallback()
                }
              }
            >
              Switch
            </Btn>
        </FormControl>
      </div>
    )
  };

  onCompanyChange(event) {
    let websitesForSelectedCompany = [];

    if (event.target.value) {
      websitesForSelectedCompany = this.props.websites.filter(
        (website) => (website.companyId === event.target.value.id)
      )
    }
    let selectedWebsite

    websitesForSelectedCompany.length > 0
    ? selectedWebsite = websitesForSelectedCompany[0]
    : selectedWebsite = null

    this.setState({selectedCompany: event.target.value, selectedWebsite})
  }

  onWebsiteChange(event) {
    this.setState({selectedWebsite: event.target.value})
  }
}

// Connect this.props to Redux and bind Redux actions.
function mapDispatchToState(dispatch) {
  return bindActionCreators(QmonActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    companies: state.qmon.companies,
    websites: state.qmon.websites,
    selected: state.qmon.selected
  }
}

// Connect the InviteEditUser class with the Redux store.
export default connect(mapStateToProps, mapDispatchToState)(ContextSwitcher);
