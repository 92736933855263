import {SET_APP_BREADCRUMB, SET_ON_INFO_CLICK, TOGGLE_SIDEBAR,HIDE_SIDEBAR} from '.';

export const setAppBreadCrumb = (breadCrumb: string) => {
  return ({
    type: SET_APP_BREADCRUMB,
    data: breadCrumb
  });
};

export const setOnInfoClick = (func) => {
  return ({
    type: SET_ON_INFO_CLICK,
    data: func
  });
};

export const toggleSidebar = () => {
  return ({
    type: TOGGLE_SIDEBAR,
    data: {}
  });
};

export const hideSidebar = () => {
  return ({
    type: HIDE_SIDEBAR,
    data: {}
  });
};

export type AppPropertiesAction = ReturnType<typeof setAppBreadCrumb | typeof setOnInfoClick>
