import { createSelector } from 'reselect'

const getSelectedCompany = state => state.selected.company
const getWebsites  = state => state.websites

export default () => {
  return createSelector(
    [getSelectedCompany, getWebsites],
    (selectedCompany, websites) => {
      const selectedCompanyId = (selectedCompany) ? selectedCompany.id : "" // check that there's already a selected company
      return websites.filter( website => website.companyId === selectedCompanyId)
    }
  )
}
