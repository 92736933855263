import {
  CHANGE_SCHEMA, CREATE_COMPONENT, CREATE_COMPONENT_PARAMETERS, CREATE_CONNECTION,
  EDIT_CONNECTION, LIST_CONNECTIONS
} from '../actions';

const initialState = {
  connectionId: 0,
  connectionType: 0,
  name: null,
  editing: false,
  connectionSchema: {},
  connectionForm: [],
  componentSchema: {},
  componentForm: [],
  connectionModel: {},
  componentModel: {},
  parameters: [],
  connections: [],
};

const connection = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SCHEMA:
      return {
        ...state,
        connectionSchema: action.connectionSchema,
        connectionForm: action.connectionForm,
        componentSchema: action.componentSchema,
        componentForm: action.componentForm
      };
    case LIST_CONNECTIONS:
      return {
        ...state,
        connections: action.connections
      }
    case EDIT_CONNECTION:
      return {
        ...state,
        connectionId: action.connectionId,
        connectionType: action.connectionType,
        name: action.name,
        editing: action.editing,
        connectionModel: action.connectionModel,
        componentId: action.componentId,
        componentModel: action.componentModel,
        parameters: action.parameters
      };
    case CREATE_CONNECTION:
      return {
        ...state,
        connectionId: action.connectionId,
        connectionType: action.connectionType,
        name: action.name,
        connectionModel: action.connectionModel
      };
    case CREATE_COMPONENT:
      return {
        ...state,
        componentModel: action.componentModel
      };
    case CREATE_COMPONENT_PARAMETERS:
      return {
        ...state,
        parameters: action.parameters
      };
    default:
      return state;
  }
};

export default connection;
