import React from 'react'
import { MenuItem } from "@material-ui/core";
import Check from 'material-ui-icons/Check';
import styled from "react-emotion";
import { PRIMARY_COLOR } from "../main.style";
import { MenuItemProps } from "@material-ui/core/MenuItem";

const SelectChecked = styled(Check)(
  {
    marginLeft: '-35px',
    paddingRight: '11px',
    paddingLeft: '35px',
    color: PRIMARY_COLOR
  }
)

const StyledMenuItem = styled(MenuItem)(
  {
    color: 'gray',
    padding: '4px',
    '&:hover':{
      color: '#000'
    }
  }
)

const Gap = styled('div')(
  {
    width: '35px',
    color: PRIMARY_COLOR
  }
)

const MenuItemCheckbox = (props: MenuItemProps) =>
  <StyledMenuItem
      {...props}
    >
      {props.selected ?
        <SelectChecked/>
        : <Gap/>}
      {props.children}
  </StyledMenuItem>

  export default MenuItemCheckbox
