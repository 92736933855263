import * as React from 'react';
import { authorize } from '../helpers/authorize'
import { Module } from '../typings/config'

export default class Authorize extends React.Component<{
  roles?: string[],
  module: Module | Module[],
}> {
  public render() {
    return <>{ this.authorized() ? this.props.children : null}</>
  }

  public authorized(){
    if(typeof this.props.module === 'string') {
      return authorize(this.props.module, this.props.roles)
    }

    if(Array.isArray(this.props.module)) {
      const authorized: boolean[] = [];

      this.props.module.forEach(x => authorized.push(authorize(x, this.props.roles)))
      return authorized.some(x => x === true)
    }


    return false
  }
}
