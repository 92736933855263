import {CREATE_STREAM_USER,
        DELETE_STREAM_USER,
        GET_STREAM_USER,
        LIST_STREAM_USERS,
        UPDATE_STREAM_USER} from './index'

import {IStreamsUser, IStreamsUserBase} from '../models/users'
import streamCRUD, {ICRUDActions, ICRUDPaths} from './stream-crud'

export class UsersActionsConstants implements ICRUDActions {
  readonly GET_ACTION_CONSTANT = GET_STREAM_USER
  readonly CREATE_ACTION_CONSTANT = CREATE_STREAM_USER
  readonly LIST_ACTION_CONSTANT = LIST_STREAM_USERS
  readonly UPDATE_ACTION_CONSTANT = UPDATE_STREAM_USER
  readonly DELETE_ACTION_CONSTANT = DELETE_STREAM_USER
}

export class UsersPathConstants implements ICRUDPaths {
  readonly GET_PATH_CONSTANT = (id: number) => `/users/${id}/`
  readonly CREATE_PATH_CONSTANT = '/users/'
  readonly LIST_PATH_CONSTANT = '/users/'
  readonly UPDATE_PATH_CONSTANT = (id: number) => `/users/${id}/`
  readonly DELETE_PATH_CONSTANT = (id: number) => `/users/${id}/`
}

const usersCRUD = new streamCRUD<IStreamsUser, IStreamsUserBase>(new UsersActionsConstants(), new UsersPathConstants())

export default usersCRUD
