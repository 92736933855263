import * as React from 'react'
import * as Sentry from '@sentry/browser';


/**
 * This component behaves like a big try catch.
 * Read more about error boundries on https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component {
    state = { hasError: false };
  
    static getDerivedStateFromError(_error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: any, info: any) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, info);
      Sentry.captureException(error);
      // console.log('info', info)
    }
  
    render() {
      if (this.state.hasError) {
        return <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontFamily: 'Roboto, Source Sans Pro'}}>
          <div style={{flex: 1}}>
            <h2>We have encountered a small problem that automatically has been reported.</h2>
          </div>
          <div style={{flex: 1}}>
            <span style={{fontSize: 24}}>
              Please <a href="#" onClick={() => window.location.reload()}>refresh</a> to continue
            </span>
          </div>
          <div style={{flex: 1}}>
            <br/>
            <small>If the problem persist, please contact: support@datastreams.io</small>
          </div>
        </div>;
      }
  
      return this.props.children;
    }
  }