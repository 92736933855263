import { IStreamsProject } from '../models/projects/index';
import { DELETE_EDGE } from '../actions/index';
import {
  CREATE_STREAM, DELETE_STREAM, EDIT_STREAM,
  GET_STREAM, LIST_STREAMS, CREATE_STREAM_PARAMETER,
  DELETE_STREAM_PARAMETER, UPDATE_STREAM_PARAMETER,
  ADD_OUTGOING_OPERATION_DATA, UPDATE_STREAM_SLA, STOP_STREAM, START_STREAM, GET_STREAM_DEBUG, CLEAR_STREAM_DEBUG
} from '../actions';

export interface IOperationEdge {
  id?: number
  source: number
  target: number
}

export interface IOperation {
  id?: number,
  description: string
  type: string
  payload?: string
  data_stream: number
  parent?: number
  children: IOperationEdge[]
  parents: IOperationEdge[]
  connection?: number
  output?: string
  alerts?: any
  options? : any
}

export interface IStream {
  id: number
  source: object
  operations: IOperation[]
  project: IStreamsProject
  name: string
  description: string
  published: boolean,
  dpu_env: string,
  environment_hash: string,
  sla: object
  publishes: IPublish[]
}

export type PublishEnv = 'dev' | 'prod'

export interface IPublish{
  id: number,
  name: string,
  namespace: string,
  env: PublishEnv,
  uid: string,
  active: boolean,
  published_at: string
}

export interface IOperationData{
  operationId: number,
  data: Array<any>,
  throughput: number
}

export type initialStreamState = {
  streams: IStream[]
  active?: IStream
  operationData: Array<IOperationData>
  debug: any
}

const initialState: initialStreamState = {
  streams: [] as IStream[],
  operationData: [],
  debug: []
}

const streams = (state = initialState, payload) => {
  switch (payload.type) {
    case ADD_OUTGOING_OPERATION_DATA:
      const OUTGOING_DATA_BUFFER_SIZE = 20

      const outgoingOperationId = payload.outgoingData.operationId
      const operationDataCopy = [...state.operationData]
      const opd = operationDataCopy.filter(opd => opd.operationId == outgoingOperationId)[0]
      if(!opd) operationDataCopy.push({operationId: outgoingOperationId, data:[], throughput:0})

      const newOperationDataState = operationDataCopy.map((opData)=>{
          if(opData.operationId == outgoingOperationId){
            if(opData.data.length > OUTGOING_DATA_BUFFER_SIZE){
              opData.data.shift()
            }
            opData.data.push(payload.outgoingData.data)
            opData.throughput++
          }
          return opData
        })

      return {
        ...state,
        operationData: newOperationDataState
      }
    case START_STREAM:
    case STOP_STREAM:
        return {
          ...state,
          active: payload.stream
        };
    case UPDATE_STREAM_SLA:
      let activeCopy = state.active || {sla:{}}
      activeCopy.sla = payload.sla
      return {
        ...state,
        active: activeCopy
      }
    case LIST_STREAMS:
      if(payload.streams){
        return {
          ...state,
          streams: [...payload.streams]
        }
      } else {
        return {...state}
      }
    case CREATE_STREAM:
      return {
        ...state,
        streams: [...state.streams, payload.stream],
        active: payload.stream
      };
    case EDIT_STREAM:
      return {
        ...state,
        active: { ...payload.stream },
      };
    case DELETE_STREAM:
      return {
        ...state,
        streams: [...(state.streams.filter(stream => stream.id !== payload.id))]
      };
    case GET_STREAM:
      return {
        ...state,
        active: { ...payload.stream },
      }
    case DELETE_EDGE:
      return {
        ...state,
        active: {
          ...state.active,
          operations: [...state.active!.operations.map(op => {
            const operation = {...op}
            operation.children = op.children.filter(edge => edge.id !== payload.edge.id)
            operation.parents = op.parents.filter(edge => edge.id !== payload.edge.id)

            return operation
          })]
        }
      }
    case CREATE_STREAM_PARAMETER:
      let stateCopy = { ...state }
      if(stateCopy.active) {
        stateCopy.active.operations.push(payload.operation)
      }
      return {
        ...stateCopy
      }
    case DELETE_STREAM_PARAMETER:
      if(state.active) {
        return {
          ...state,
          active: {
            ...state.active,
            operations: state.active.operations.filter(op => op?.id !== payload.operation.id)
          }
        }
      }
      return {
        ...state
      }
    case UPDATE_STREAM_PARAMETER:
      if (state.active) {
        state.active.operations = state.active.operations.map((op) => {
          if (op.id == payload.operation.id)
            return payload.operation
          return op
        })
      }
      return {
        ...state
      }
    case GET_STREAM_DEBUG:
      return {
        ...state,
        debug: [...payload.payload]
      }
    case CLEAR_STREAM_DEBUG:
      return {
        ...state,
        debug: []
      }
    default:
      return state
  }
};

export default streams
