import {HIDE_LOADING_INDICATOR, SHOW_LOADING_INDICATOR} from '../actions';
import { LoadingAction } from '../actions/loading';


export interface ILoading {
  show: boolean
}

const initialState: ILoading = {
  show: false
};

const loading = (state: ILoading = initialState, action: LoadingAction) => {
  switch (action.type) {
    case SHOW_LOADING_INDICATOR:
    case HIDE_LOADING_INDICATOR:
      return {
        ...state,
        show: action.show
      };
    default:
      return state;
  }
};

export default loading;