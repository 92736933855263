import {COMPANIES_LIST} from '../actions';

export interface ICompany {
  sfId: string,
  name: string,
}

export interface ICompanyState {
  companies: ICompany[]
}

const initialState: ICompanyState = {
  companies: []
};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_LIST:
      return {
        ...state,
        companies: action.companies
      };
    default:
      return state;
  }
};

export default companies;