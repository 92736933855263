import {RIGHTS_LIST} from '.';
import {store} from '..';
import instance from '../helpers/instance';
import {IRight} from '../reducers/rights'
import {retrieveCompanies} from './companies';

const returnRights = (rights: IRight[]) => ({
  type: RIGHTS_LIST,
  rights
});

export const retrieveRights = () => {
  const userId = store.getState().settings.user_id;

  return dispatch => {
    instance.get(`/users/${userId}/permissions`)
      .then(result => {
        dispatch(returnRights(result.data));
        dispatch(retrieveCompanies());
      })
      .catch(error => console.error(error));
  }
};