import instance from '../helpers/instance';

import {CHANGE_COMPANY, USER_SETTINGS} from '.';
import {ICompany} from '../reducers/companies'
import {retrieveDomains} from './domains';
import {retrieveRights} from "./rights";

// tslint:disable-next-line:variable-name
const changeCompanyFinal = (company_name: ICompany['name'], company_id: ICompany['sfId']) => {
  sessionStorage.setItem('company_name', company_name);
  sessionStorage.setItem('company_id', company_id);

  return ({
    type: CHANGE_COMPANY,
    company_name,
    company_id
  });
};

// tslint:disable-next-line:variable-name
export const changeCompany = (company_name: ICompany['name'], company_id: ICompany['sfId']) => {
  return dispatch => {
    dispatch(changeCompanyFinal(company_name, company_id));
    dispatch(retrieveDomains());
  }
};

const retrieveUserSettingsFinal = (user) => ({
  type: USER_SETTINGS,
  user_email: user.email,
  user_roles: user.roles,
  user_companies: user.companies,
  user_id: user.id,
  user_firstname: user.firstName,
  user_lastname: user.lastName
});
// tslint:disable-next-line:variable-name
export const retrieveUserSettings = (user_id: string) => dispatch => {
  return instance.get(`/users/${user_id}`)
    .then(result => {
      dispatch(retrieveUserSettingsFinal(result.data));
      dispatch(retrieveRights());
    })
    .catch(error => console.error(error));
};
