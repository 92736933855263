import instance from '../helpers/streams-instance';
import {CREATE_STREAM_COMPANY,
        DELETE_STREAM_COMPANY,
        GET_STREAM_COMPANY,
        LIST_STREAM_COMPANIES,
        UPDATE_STREAM_COMPANY,
        SELECT_STREAM_COMPANY} from './index'
import {showLoading, hideLoading} from './loading'
import {IStreamsCompany, IStreamsCompanyBase} from '../models/companies'
import { showMessage } from './message';
import User from '../models/users/user'

/* START--------- GET *-------- */
const returnGetStreamCompany = (company: IStreamsCompany) => {
  return ({
      type: GET_STREAM_COMPANY,
      company
  })
}

export const getStreamCompany = (id: number) =>{
  return dispatch => {
      instance.get(`/companies/${id}/`)
      .then(result => dispatch(returnGetStreamCompany(result.data)))
      .catch(error => console.error(error));
  }
}
/* ------------ GET *--------END */


/* START--------- CREATE *-------- */
const returnCreateStreamCompany = (company: IStreamsCompany) => {
  return ({
      type: CREATE_STREAM_COMPANY,
      company
  })
}

export const createStreamCompany = (payload: IStreamsCompanyBase) =>{
  return async dispatch => {
      dispatch(showLoading())
      instance.post('/companies/', payload)
      .then(result => {
        User.get().add_group('administrators', result.data.id)
        dispatch(returnCreateStreamCompany(result.data))
        dispatch(listStreamCompanies())
        dispatch(hideLoading())
        dispatch(showMessage('Company created successfully'))
        return result.data
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
        dispatch(showMessage('There was a problem trying to create the project' + error.toString()))
      });
  }
}
/* ------------ CREATE *--------END */


/* START--------- LIST *-------- */
const returnListStreamCompanies = (companies: IStreamsCompany[]) => {
  return ({
      type: LIST_STREAM_COMPANIES,
      companies
  })
}

export const listStreamCompanies = (cb = ()=>{}) =>{
  return dispatch => {
      instance.get('/companies/')
      .then(result => dispatch(returnListStreamCompanies(result.data)))
      .then(()=> cb())
      .catch(error => console.error(error));
  }
}
/* ------------ LIST *--------END */


/* START--------- UPDATE *-------- */
const returnUpdateStreamCompany = (company: IStreamsCompany) => {
  return ({
      type: UPDATE_STREAM_COMPANY,
      company
  })
}

export const updateStreamCompany = (id: number, payload: IStreamsCompany) =>{
  return dispatch => {
      dispatch(showLoading())
      instance.patch(`/companies/${id}/`, payload)
      .then(result => {
        dispatch(returnUpdateStreamCompany(result.data))
        dispatch(listStreamCompanies())
        dispatch(hideLoading())
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
      });
  }
}
/* ------------ UPDATE *--------END */


/* START--------- DELETE *-------- */
const returnDeleteStreamCompany = (company: IStreamsCompany) => {
  return ({
      type: DELETE_STREAM_COMPANY,
      company
  })
}

export const deleteStreamCompany = (id: number) =>{
  return dispatch => {
      dispatch(showLoading())
      instance.delete(`/companies/${id}/`)
      .then(result => {
        dispatch(listStreamCompanies())
        dispatch(returnDeleteStreamCompany(result.data))
        dispatch(hideLoading())
      })
      .catch(error => {
        dispatch(hideLoading())
        console.error(error)
        dispatch(showMessage('There was a problem trying to delete the company: ' + error.toString()))
        if( error?.response?.status === 412 && error?.response?.data?.e &&
            error?.response?.data?.e.indexOf("Cannot delete some instances of") > -1) {
               let errorMessageStart = 'Cannot delete because first the following items need to be deleted: ';
               let errorMessage = errorMessageStart +error?.response?.data?.items;
               dispatch(showMessage('There was a problem trying to delete the company: ' + errorMessage ))
        } else {
          dispatch(showMessage("An unknown error occurred"))
        }
      });
  }
}
/* ------------ DELETE *--------END */

/* START--------- SELECT *-------- */
export const selectCompany = (company: IStreamsCompany) => {
  return {
    type: SELECT_STREAM_COMPANY,
    company
  }
}
/* ------------ SELECT *--------END */
