import * as React from 'react'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { IStreamsCompany } from '../models/companies';
import { IStreamsProject } from '../models/projects';
import MenuItemCheckbox from './menu-item-checkbox';


type CompanyProject = {
    company: IStreamsCompany
    project: IStreamsProject
}

const sortCompanyProjectByName = (cpA: CompanyProject, cpB: CompanyProject) => {
    return (''+cpA?.company?.name).localeCompare(cpB?.company?.name) || 
        (''+cpA?.project?.name).localeCompare(cpB?.project?.name)
}

interface IInputSelectionProps {
    /** The label's display name. */
    name: string
    /** The currently selected entity. */
    selected: CompanyProject
    /** The list of entities to choose from. */
    entities: CompanyProject[]
    /**
     * The callback method including the entity as a parameter.
     * This callback gets triggered once one chooses an item on the selection
     * field.
     * */
    onChange: (entity: CompanyProject) => void
}

/**
 * This input selection contains an input field
 * and a label.
 */
const InputSelection: React.FC<IInputSelectionProps> = (props) =>  {
    const [selected, setSelected] = React.useState<null | number>(props.selected && props.selected.project ? props.selected.project.id : null)

    /**
     * Helper method which resolves an id to it's object.
     *
     * @param id the id to resolve to an object.
     */
    const getSelected = (id: number) => props.entities.find(entity => entity.project.id === id)

    /**
     * Handles the `onChange` event for the Select element.
     */
    const handleChange = event => {
        // Resolve object by id.
        const selectedResolved = getSelected(event.target.value)

        setSelected(event.target.value)
        if(selectedResolved) {
            props.onChange(selectedResolved)
        }
    }

        return (
            <FormControl fullWidth={true}>
                <InputLabel htmlFor={`select-${props.name}`}>{props.name}</InputLabel>
                <Select
                    value={selected && getSelected(selected) ? selected : ''}
                    onChange={handleChange}
                    inputProps={{
                        name: `select-${props.name}`,
                        id: `select-id-${props.name}`
                    }}>
                        {props.entities && props.entities.sort(sortCompanyProjectByName).map((entity) =>
                            <MenuItemCheckbox value={entity.project.id} key={entity.project.id}>
                                {entity.company.name} / {entity.project.name}
                            </MenuItemCheckbox>
                        )}
                </Select>
        </FormControl>)
}

interface IContextSwitcherProps {
    /**
     * A list of available companies.
     */
    companies: IStreamsCompany[]
    /**
     * A list of available projects.
     */
    projects: IStreamsProject[]
    /**
     * The currently selected company context.
     */
    selectedCompany: IStreamsCompany
    /**
     * The currently selected project context.
     */
    selectedProject: IStreamsProject
    /**
     * Callback method which gets triggered once someone has
     * selected a company from the selection element.
     */
    onPrimarySelection: (entity: IStreamsCompany) => void
    /**
     * Callback method which gets triggered once someone has
     * selected a project from the selection element.
     */
    onSecondarySelection: (entity: IStreamsProject) => void
    onCloseCallback: () => void
}

const getCompanyFromProject = (project: IStreamsProject, companies: IStreamsCompany[]) => companies.find(company => project.company === company.id)

/**
 * This context-switcher component should be used
 * to switch between company and projects.
 */
export default class ContextSwitcher extends React.Component<IContextSwitcherProps> {
    public static defaultProps = {
        companies: [],
        projects: [],
    }
    render() {
        const selectedCompany = getCompanyFromProject(this.props.selectedProject, this.props.companies)
        return(
            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <InputSelection
                        name="Project"
                        entities={this.props.projects.map(project => { return {company: getCompanyFromProject(project, this.props.companies), project: project} as CompanyProject})}
                        selected={{company: selectedCompany, project: this.props.selectedProject} as CompanyProject}
                        onChange={(entity) => {
                            this.props.onPrimarySelection(entity.company)
                            this.props.onSecondarySelection(entity.project)
                        }} />
                </Grid>
            </Grid>
        )
    }
}