import { LABELS_LIST, LABELS_COLUMN_ADD, LABELS_DELETE, LABELS_CELL_UPDATE, LABELS_ROW_ADD, LABELS_ADD, LABELS_COLUMN_UPDATE, LABELS_COLUMN_DELETE, LABELS_LIST_ITEM_DELETE, LABELS_LIST_ITEM_ADD } from '../actions/labels';

/**
 * A label template contains columns and cells.
 */
export interface ILabelTemplate {
  id: number
  columns: ILabelColumn[]
  name: string
  project: number
}

type LabelColumnType = 'text' | 'list' | 'formula'

export interface ILabelColumn {
  id: number
  name: string
  type: LabelColumnType
  cells: ILabelCell[]
  template_id?: number
  list_items: ILabelColumnListItem[]
}

export interface ILabelColumnListItem {
  id: number
  column: number
  value: string | number
}

export type ILabelColumnList = ILabelColumn & ILabelColumnListItem

export interface ILabelCell {
  id: number
  column_id: number
  order_id: number
  value?: string | number
}

export interface ILabelReducerState {
  labels: ILabelTemplate[]
}

const initialState: ILabelReducerState = {
  labels: [] as ILabelTemplate[],
}

type BaseMatrixCell = ILabelCell & {type: LabelColumnType}
export type MatrixCellText = BaseMatrixCell
export type MatrixCellList = BaseMatrixCell & {value?: number, list_items: ILabelColumnListItem[]}

export type MatrixCell = MatrixCellText | MatrixCellList | null
export const toMatrix = (columns: ILabelColumn[]) => {
  const matrix: MatrixCell[][] = []

  const matrixWidth = columns.length
  // Increment result by 1 since we start counting from 0.
  let matrixHeight = Math.max(...columns.flatMap(col => col.cells).flatMap(cell => cell.order_id)) + 1
  if(matrixHeight < 1) {
    matrixHeight = 0
  }

  // Fill the matrix with nulls based on it's dimensions.
  Array(matrixHeight).fill(0).forEach((_) => matrix.push(new Array(matrixWidth).fill(null)))
  columns.forEach((column, column_id) => {
    column.cells.forEach((cell, _row_id) => {
      let matrix_cell = {...cell, type: column.type}
      if(column.type === 'list') {
          matrix_cell = {...matrix_cell, list_items: [...column.list_items]} as MatrixCellList
      }
      matrix[cell.order_id][column_id] = matrix_cell
    })
  })

  return matrix
}


const labels = (state = initialState, action): ILabelReducerState => {
  switch(action.type) {
    case LABELS_LIST:
      return {
        labels: [...action.labels]
      }
    case LABELS_ADD:
      return {
        labels: [{...action.label}, ...state.labels]
      }
    case LABELS_DELETE:
      return {
        labels: [...state.labels.filter(lbl => lbl.id !== action.template_id)]
      }
    case LABELS_CELL_UPDATE:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}
          lbl_copy.columns.forEach((column: ILabelColumn, column_idx: number) => {
            column.cells.forEach((cell: ILabelCell, cell_idx: number) => {
              if(cell.id === action.cell.id) {
                lbl_copy.columns[column_idx][cell_idx] = {...action.cell}
              }
            })
          })

          return lbl_copy
        })]
      }
    case LABELS_COLUMN_ADD:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}
          if(lbl_copy.id !== action.template_id) {
            return lbl_copy
          }

          // Append newly fetched column.
          lbl_copy.columns = [...lbl_copy.columns, action.column]
          return lbl_copy
        })]
      }
    case LABELS_COLUMN_DELETE:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}

          lbl_copy.columns = [...lbl_copy.columns.filter(col => col.id !== action.column_id)]

          return lbl_copy
        })]
      }
    case LABELS_COLUMN_UPDATE:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}

          lbl_copy.columns.forEach((column: ILabelColumn, column_idx: number) => {
            if(column.id === action.column.id) {
              lbl_copy.columns[column_idx] = {...action.column}
            }
          })

          return lbl_copy
        })]
      }
    case LABELS_LIST_ITEM_ADD:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}
          lbl_copy.columns.forEach((column: ILabelColumn, column_idx: number) => {
            // We can skip unrelated columns.
            if(column.id !== action.item.column) return

            lbl_copy.columns[column_idx].list_items = [{...action.item}, ...column.list_items]
          })

          return lbl_copy
        })]
      }
    case LABELS_LIST_ITEM_DELETE:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}
          lbl_copy.columns.forEach((column: ILabelColumn, column_idx: number) => {
            // No need to filter on non-list columns
            if(column.type !== 'list') return

            lbl_copy.columns[column_idx].list_items = [...column.list_items.filter(item => item.id !== action.item_id)]
            column.cells.forEach((cell: ILabelCell, cell_idx: number) => {
              if(cell.value === action.item_id) {
                // Mark -1; meaning no value.
                lbl_copy.columns[column_idx].cells[cell_idx] = {...cell, value: -1}
              }
            })
          })
          return lbl_copy
        })]
      }
    case LABELS_ROW_ADD:
      return {
        labels: [...state.labels.map((lbl: ILabelTemplate) => {
          let lbl_copy = {...lbl}
          if(lbl_copy.id !== action.label.id) {
            return lbl_copy
          }

          return {...action.label}
        })]
      }
    default:
      return state
  }
}

export default labels
