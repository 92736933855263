import {COMPANIES_LIST} from '.';
import instance from '../helpers/instance';
import {ICompany} from '../reducers/companies'
import {retrieveDomains} from "./domains";

const returnCompanies = (companies: ICompany[]) => {
  return ({
    type: COMPANIES_LIST,
    companies,
    company_name: sessionStorage.getItem('company_name') ? sessionStorage.getItem('company_name') : companies[0].name,
    company_id: sessionStorage.getItem('company_id') ? sessionStorage.getItem('company_id') : companies[0].sfId
  });
};

export const retrieveCompanies = () => {
  return dispatch => {
    // instance.get(checkRights("companies.read.all") ? '/companies' : `/users/${userId}/companies`)
    instance.get(`/companies/`)
      .then(result => {
        dispatch(returnCompanies(result.data));
        dispatch(retrieveDomains());
      })
      .catch(error => console.error(error));
  }
};
