import {CREATE_STREAM_SEGMENT,LIST_STREAM_SEGMENTS, UPDATE_STREAM_SEGMENT, 
  DELETE_STREAM_SEGMENT,  GET_STREAM_SEGMENT  } from '../actions'
import {IStreamsSegments} from '../models/segments'

type initialState = {
  segments: IStreamsSegments[]
}

const initialState: initialState = {
  segments: [] as IStreamsSegments[]
}

const streamSegments= (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STREAM_SEGMENT:
    return {
      segments: [...state.segments, action.segment]
    }
    case DELETE_STREAM_SEGMENT:
      return state
    case GET_STREAM_SEGMENT:
      let newSegments = state.segments; 
      if( state.segments.length > 0 ){
         const foundIndex = state.segments.findIndex(x => x.id == action.segment.id);
         newSegments[foundIndex] = action.segment;  
      }else{
        newSegments = [...state.segments, action.segment];
      }
      let segmentList = {
        segment:  action.segment,
        segments: newSegments
      }
      return segmentList; 
    case LIST_STREAM_SEGMENTS:
      return {
        segments: action.segments
      }
    case UPDATE_STREAM_SEGMENT:
      return state
    default:
      return state;
  }
}

export default streamSegments
